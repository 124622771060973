import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICiudad } from '../models/ciudad';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IDataCostoUnidad } from '../models/data_costounidad';
import { ICostoVentaMensual } from '../models/costoventamensual';
import { ITipoDiasTrabajados } from '../models/tipoDiasTrabajados';
import { ITipoRenta } from '../models/tipoRenta';
import { IMetodoPagoProveedor } from '../models/metodoPagoProveedor';

@Injectable({
  providedIn: 'root'
})
export class CostounidadService {

  private apiUrl: string ='';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'CostosUnidad';
  }

  getListadoTipoRenta(): Observable<ITipoRenta[]> {
    return this.http
      .get<ITipoRenta[]>(this.apiUrl + '/getTipoRenta')
      .pipe(retry(1), catchError(this.handleError));
  }
  getListadoTipoDiasTrabajados(): Observable<ITipoDiasTrabajados[]> {
    return this.http
      .get<ITipoDiasTrabajados[]>(this.apiUrl + '/getTipoDiasTrabajo')
      .pipe(retry(1), catchError(this.handleError));
  }
  getListadoTipoPago(): Observable<IMetodoPagoProveedor[]> {
    return this.http
      .get<ITipoDiasTrabajados[]>(this.apiUrl + '/getTipoPago')
      .pipe(retry(1), catchError(this.handleError));
  } 
  getListado(cod_usuario:string): Observable<any[]> {
    let cod_usuario_cast = cod_usuario=='undefined' ? '': cod_usuario!; 
    const valores = {
      params: new HttpParams().
        set('cod_usuario', cod_usuario_cast)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/getCostoPorMes', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoCostoMensual(num_opcion: number,num_mes: number,num_anio:number): Observable<ICostoVentaMensual[]> {
    const valores = {
      params: new HttpParams().
        set('num_mes', num_mes).
        set('num_anio', num_anio)
    };
    return this.http
      .get<ICostoVentaMensual[]>(this.apiUrl + '/GetListadoCostoMensual',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoEncabezados(fecha:string,fecha_fin:string,num_opcion:number): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin).
        set('num_opcion', num_opcion)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/rptHeadersCostoUnidad', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getInformacionReporte(fecha:string,fecha_fin:string): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/rptCostoUnidad', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  public guardarCostoUnidad(data:IDataCostoUnidad):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/guardarCostoPorMes/',body,{'headers':headers});
   }

   public actualizarCostoPorMes(data:IDataCostoUnidad):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this.http.post<any>(this.apiUrl + '/ActualizarCostoPorMes/',body,{'headers':headers});
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
        return errorMessage;
    });
  }

  //Reporte Concentrado por Proyectos 
  getReporteConcentradoInicioYFin(fecha:string,fecha_fin:string): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/rptCostoUnidadConcentrado', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  //Reporte Diferencia por Costo de Operacion(Ciudad)
  getReporteCostoOperacionDiferenciaMensual(num_mes: number,num_anio:number): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('num_mes', num_mes).
        set('num_anio', num_anio)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetListadoCostoOperacionDiferencia',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  //Reporte Aprovechamiento por Proyectos Rentas y Subcontrataciones
  getReporteAprovechamientoRentaSubInicioYFin(fecha:string,fecha_fin:string): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetAprovechamientoRentaSub', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
   //Reporte Diferancias por Proyectos Rentas y Subcontrataciones
  getReporteDiferenciasRentaSubInicioYFin(num_mes:number,num_anio:number): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('num_mes', num_mes).
        set('num_anio', num_anio)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetDiferenciaRentaSub', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
   //Reporte Concentrado por Proyectos Rentas y Subcontrataciones
   getReporteConcentradoRentaSubInicioYFin(fecha:string,fecha_fin:string): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetConcentradoRentaSub', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getReporteMensualRentaSub(num_mes: number,num_anio:number): Observable<any[]> {
    const valores = {
      params: new HttpParams().
      set('num_mes', num_mes).
      set('num_anio', num_anio)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/getReporteMensualRentaSubInicioYFin', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
  getReporteMensualGPS(num_mes:number,num_anio:number): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('num_mes', num_mes).
        set('num_anio', num_anio)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetMensualGPS', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
   //Reporte Concentrado por Proyectos Rentas y Subcontrataciones
   getReporteConcentradoGPS(fecha:string,fecha_fin:string): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetConcentradoGPS', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getReporteMensualDiferenciaGPS(num_mes: number,num_anio:number): Observable<any[]> {
    const valores = {
      params: new HttpParams().
      set('num_mes', num_mes).
      set('num_anio', num_anio)
    };
    return this.http
      .get<any[]>(this.apiUrl + '/GetMensualDiferenciaGPS', valores)
      .pipe(retry(1), catchError(this.handleError));
  }
}
