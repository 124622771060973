<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="folio" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-sun mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline">Operacion Diaria Real</div>
              <div *ngIf="dia.length > 0" style="color:blueviolet;display:inline;" class="ml-3"> - {{dia |
                date: 'dd/MM/yyyy'}} </div>
            </header>
            <div class="row d-flex justify-content-end">
              <div class="col-md-12">
                <div class="form-group row mt-5">
                  <div class="col-md-8">
                    <div class="row d-flex justify-content-start">
                      <div class="col-sm-3">
                        <label for="lblligero" class="col-form-label color-font text-right"
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          Fecha
                        </label>
                      </div>
                      <div class="col-sm-5">
                        <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha"
                          tooltipPosition="top" [(ngModel)]="fecha_filtrada" (onChange)="asignarValorFecha()">
                        <hr class="my-1">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <button pButton pRipple type="button" (click)="FiltrarFechaDiariaRuta();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="row mt-5">
              <p-table #dt1 [value]="lstOperacionDiariaReal" dataKey="id" editMode="row" [scrollable]="true"
                scrollDirection="both" scrollHeight="600px" [style]="{'min-width': '50rem'}"
                [globalFilterFields]="['nombre_ciudad','nombre_cliente','nombre_ruta','nombre_tipo_transporte']"
                [loading]="loading" [paginator]="true" [rows]="50" [rowsPerPageOptions]="[50,150,100,200]"
                [showCurrentPageReport]="true" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                sortField="nombre_ciudad">
                <ng-template pTemplate="caption">
                  <div class="flex flex-row-reverse flex-wrap">
                    <div class="col-sm-3 ml-5">
                      <!--<p-columnFilter field="clv_especial" matchMode="contains" [showMenu]="false"
                                                tooltip="Filtrar tipo de Ruta" tooltipPosition="top">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="lstEstatusEspecial"
                                                        optionLabel="nombre" optionValue="id"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por Ruta Especial" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>-->
                    </div>
                    <span class="p-input-icon-left p-ml-auto">
                      <button class="btn btn-danger" (click)="limpiarfiltrosTabla();">Limpiar
                        Filtros</button>
                    </span>
                  </div>
                  <div class="row d-flex justify-content-end mt-5 mb-5">
                    <div class="col-md-3">
                      <p-columnFilter type="text" field="nombre_ciudad" placeholder="Ciudad"
                        class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                      <p-columnFilter type="text" field="nombre_cliente" placeholder="Cliente"
                        class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                      <p-columnFilter type="text" field="nombre_proyecto" placeholder="Proyecto"
                        class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                      <p-columnFilter type="text" field="nombre_ruta" placeholder="Ruta"
                        class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <!--<th pFrozenColumn style="min-width:100px; width: 180px;">Ciudad</th>-->
                    <th pSortableColumn="nombre_ciudad" class="text-center" [ngClass]="{'texto_sm':true}"
                      style="min-width:100px; width: 130px;">Ciudad
                      <p-sortIcon field="nombre_ciudad"></p-sortIcon>
                    </th>
                    <!--<th pSortableColumn="nombre_cliente" pFrozenColumn style="min-width:100px;width: 100px;" [ngClass]="{'texto_sm':true}">Cliente<p-sortIcon
                                            field="nombre_cliente"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_proyecto" pFrozenColumn style="min-width:100px;width: 100px;" [ngClass]="{'texto_sm':true}">Proyecto<p-sortIcon
                                            field="nombre_proyecto"></p-sortIcon>
                                        </th>
                                        -->
                    <th pSortableColumn="nombre_ruta" class="text-center" [ngClass]="{'texto_sm':true}"
                      style="min-width:100px; width: 180px;">Ruta
                      <p-sortIcon field="nombre_ruta"></p-sortIcon>
                    </th>
                    <!-- <th pSortableColumn="nombre_tipo_transporte" pFrozenColumn style="min-width:20px; width: 40px;"
                      [ngClass]="{'texto_sm':true}">Tipo
                      Transporte<p-sortIcon field="nombre_tipo_transporte"></p-sortIcon>
                    </th> -->
                    <th pFrozenColumn pSortableColumn="num_proyeccion" [ngClass]="{'texto_sm':true}"
                      style="min-width:40px; width: 50px;" class="text-center">
                      Programado<p-sortIcon field="num_proyeccion"></p-sortIcon>
                    </th>
                    <th pFrozenColumn pSortableColumn="num_real" [ngClass]="{'texto_sm':true}"
                      style="min-width:90px; width: 90px;" class="text-center">
                      Prestado<p-sortIcon field="num_real"></p-sortIcon>
                    </th>
                    <th class="text-center" style="min-width:120px;width: 180px;" [ngClass]="{'texto_sm':true}">Unidad
                    </th>
                    <th *ngIf="clv_permiso_admin && fecha_filtrada!=fecha_dia_final" class="text-center"
                      style="min-width:120px;width: 120px;" [ngClass]="{'texto_sm':true}">Admi
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td class="text-center" style="min-width:100px; width: 180px;" [ngClass]="{'col-header':true}">
                      {{rowData.nombre_ciudad}}
                    </td>
                    <!--<td style="min-width:100px;width: 100px;">
                                            {{rowData.nombre_cliente}}
                                        </td>
                                        <td style="min-width:100px;width: 100px;">
                                            {{rowData.nombre_proyecto}}
                                        </td>-->
                    <td style="min-width:200px; width: 200px;"
                      [ngClass]="{'col-header':true,'col-especial':rowData.clv_especial==1,'texto_sm':true}">
                      {{rowData.nombre_ruta}}
                    </td>
                    <!-- <td style="min-width:20px; width: 40px;" [ngClass]="{'col-blue':true}">
                      {{rowData.nombre_tipo_transporte}}
                    </td> -->
                    <td style="min-width:40px; width: 50px;" [ngClass]="{'col-lila':true}">
                      <div class="row d-flex justify-content-center">
                        <!--<div class="col-sm-6">
                                                    <i class="pi pi-check" style="font-size: 1.5rem"
                                                        *ngIf="rowData.num_proyeccion!=0"></i>
                                                </div>-->
                        <div class="col-sm-3">
                          <h4 pTooltip="Numero de Servicios" tooltipPosition="top" class="text-center font-weight-bold">
                            {{rowData.num_proyeccion}}
                          </h4>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:90px; width: 90px;">
                      <div class="row  g-0">
                        <div class="col-sm-12" *ngIf="rowData.num_real>0">
                          <div class="row d-flex justify-content-center">
                            <div class="col-sm-4 d-flex justify-content-center">
                              <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary"
                                [disabled]="true"></p-checkbox>
                            </div>
                            <div class="col-sm-4 d-flex justify-content-center">
                              <h4 class="font-weight-bold" pTooltip="Numero de Servicios" tooltipPosition="top">
                                {{rowData.num_real}}</h4>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12" *ngIf="rowData.num_real<=0">
                          <div class="row d-flex justify-content-center">
                            <div class="col-sm-4 d-flex justify-content-center">
                              <p-checkbox [(ngModel)]="checked2" [binary]="true" inputId="binary"
                                [disabled]="true"></p-checkbox>
                            </div>
                            <div class="col-sm-4 d-flex justify-content-center">
                              <h4 class="font-weight-bold" pTooltip="Numero de Servicios" tooltipPosition="top">
                                {{rowData.num_real}}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <!--CON TABULADOR-->
                    <td style="text-align:center; min-width:120px;width: 180px;" *ngIf="rowData.clv_tabulado==1">
                      <div class="row g-0">
                        <div class="col-sm-2">
                          <button pButton type="button" icon="pi pi-plus-circle" class="p-button-rounded  p-button-text"
                            pTooltip="Agregar servicio Normal" tooltipPosition="top"
                            (click)="agregarUnidadxDia(rowData, 0, 1)"
                            [disabled]="fecha_filtrada<fecha_dia_final || fecha_filtrada>fecha_dia_final">
                          </button>
                        </div>
                        <div class="col-sm-3">
                          <button pButton type="button" icon="pi pi-plus-circle"
                            class="p-button-rounded p-button-text p-button-help" pTooltip="Agregar servicio Especial"
                            tooltipPosition="top" (click)="agregarUnidadxDia(rowData,1,2)"
                            [disabled]="fecha_filtrada<fecha_dia_final || fecha_filtrada>fecha_dia_final">
                            <p class="pl-1 my-0" style="font-size: 9px;">Especial</p>
                          </button>
                        </div>
                        <div class="col-sm-3" *ngIf="this.clv_operacion == 3">
                          <button pButton type="button" icon="pi pi-plus-circle"
                            class="p-button-rounded p-button-text p-button-danger" pTooltip="Agregar servicio Sin Cobro"
                            tooltipPosition="top" (click)="agregarUnidadxDia(rowData,2,2)"
                            [disabled]="(fecha_filtrada<fecha_dia_final || fecha_filtrada>fecha_dia_final)">
                            <p class="pl-1 my-0" style="font-size: 9px;">Sin cobro</p>
                          </button>
                        </div>
                        <div class="col-sm-3" *ngIf="this.clv_operacion == 3">
                          <button pButton type="button" icon="pi pi-plus-circle"
                            class="p-button-rounded p-button-text p-button-contrast" pTooltip="Agregar servicio Sin Nomina"
                            tooltipPosition="top" (click)="agregarUnidadxDia(rowData,3,2)"
                            [disabled]="(fecha_filtrada<fecha_dia_final || fecha_filtrada>fecha_dia_final)">
                            <p class="pl-1 my-0" style="font-size: 9px;">Sin Nomina</p>
                          </button>
                        </div>
                        <div class="col-sm-1">
                          <button pButton type="button" icon="pi pi-align-justify"
                            class="p-button-rounded p-button-warning p-button-text" pTooltip="Ver Servicios"
                            tooltipPosition="top" (click)="consultaInformacionDeUnidades(rowData);"
                            [disabled]="rowData.num_real<=0"></button>
                        </div>
                      </div>
                    </td>
                    <!--FIN CON TABULADOR-->
                    <!--SIN TABULADOR (DESACTIVADO)-->
                    <td style="text-align:center; min-width:120px;width: 120px;" *ngIf="rowData.clv_tabulado==0">
                      <div class="row g-0">
                        <div class="col-sm-3">
                          <p class="col-especial">
                            <i class="pi pi-times" pTooltip="Sin Tabulador"></i>
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p class="col-especial">
                            <i class="pi pi-times" pTooltip="Especial-Sin Tabulador"></i>
                          </p>
                        </div>
                        <div class="col-sm-3">
                          <button pButton type="button" icon="pi pi-align-justify"
                            class="p-button-rounded p-button-warning p-button-text" pTooltip="Ver Servicios"
                            tooltipPosition="left" (click)="consultaInformacionDeUnidades(rowData);"
                            [disabled]="rowData.num_real<=0"></button>
                        </div>
                      </div>
                    </td>
                    <!--FIN SIN CON TABULADOR (DESACTIVADO)-->
                    <td *ngIf="clv_permiso_admin && fecha_filtrada!=fecha_dia_final">
                      <div class="row d-flex justify-content-center">
                        <div class="col-sm-3 d-flex justify-content-center">
                          <button pButton type="button" icon="pi pi-plus-circle" 
                            class="p-button-rounded p-button-text"
                            pTooltip="Agregar Normal-Admin" tooltipPosition="top"
                            (click)="agregarUnidadxDia(rowData,0,3)">
                          </button>
                        </div>
                        <div class="col-sm-3 d-flex justify-content-center">
                          <button pButton type="button" icon="pi pi-plus-circle" 
                            class="p-button-rounded p-button-text p-button-help"
                            pTooltip="Agregar Especial-Admin" tooltipPosition="top"
                            (click)="agregarUnidadxDia(rowData,1,4)">
                          </button>
                        </div>
                        <div class="col-sm-3 d-flex justify-content-center">
                          <button pButton type="button" icon="pi pi-plus-circle" 
                            class="p-button-rounded p-button-text p-button-danger"
                            pTooltip="Agregar Sin Cobro-Admin" tooltipPosition="top"
                            (click)="agregarUnidadxDia(rowData,2,4)">
                          </button>
                        </div>
                        <div class="col-sm-3 d-flex justify-content-center">
                          <!-- class="p-button-rounded p-button-text p-button-contrast"  -->
                          <button pButton type="button" icon="pi pi-plus-circle"
                            class="p-button-rounded p-button-text p-button-contrast" pTooltip="Agregar Sin Nomina-Admin"
                            tooltipPosition="top" (click)="agregarUnidadxDia(rowData,3,4)">
                          </button>
                        </div>
                        <!-- <div class="col-sm-6 d-flex justify-content-center">
                          <button pButton type="button" icon="pi pi-plus-circle" class="p-button-help"
                            pTooltip="Agregar Especial-SinCobro-Admin" tooltipPosition="top"
                            (click)="agregarUnidadxDia(rowData,2,4)">
                          </button>
                        </div> -->
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="row mt-5 mb-5 d-flex justify-content-center">
              <div class="col-6  d-flex justify-content-center">
                <p-colorPicker [(ngModel)]="color_rojo_especial" [disabled]="true"></p-colorPicker>
                <p class="text-left informativo" style="display: inline;">Rutas Especiales</p>
              </div>
            </div>
            <div class="row mt-5 mb-5 d-flex justify-content-center" *ngIf="fecha_filtrada<fecha_dia_final">
              <div class="col-6  d-flex justify-content-center">
                <p class="text-left informativo-fechas" style="display: inline;">*No se pueden agregar
                  registros de una Fecha Anterior*</p>
              </div>
            </div>
            <div class="row mt-5 mb-5 d-flex justify-content-center" *ngIf="fecha_filtrada>fecha_dia_final">
              <div class="col-6  d-flex justify-content-center">
                <p class="text-left informativo-fechas" style="display: inline;">*No se pueden agregar
                  registros de una Fecha Posterior*</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--AGREGAR UNIDAD DE INFORMACION-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspEditar" [style]="{width: '80vw','height':'700px'}"
      [transitionOptions]="'0ms'" styleClass="card-shadow">
      <ng-template pTemplate="header">
        Agregar Servicio
        <div *ngIf="dspEditar && clv_especial == 1" style="color:red;">
          <p  class="col-md-6 mt-2">ESPECIAL</p>
        </div>
        <div *ngIf="dspEditar && this.clv_especial == 2" style="color:orange;">
          <p class="col-md-6 mt-2">SIN COBRO</p>
        </div>
        <div *ngIf="dspEditar && this.clv_especial == 3" style="color:rgb(255, 0, 0);">
          <p class="col-md-6 mt-2">SIN NOMINA</p>
        </div>
        <div *ngIf="dspEditar && clv_especial == 0" style="color:black;">
          {{nombre_ruta_Editable }} | {{fecha_dia_editable | date: 'dd/MM/yyyy'}}
        </div>
        <div *ngIf="dspEditar && clv_especial != 0" style="color:black;">
          {{nombre_ruta_Editable }} | {{fecha_dia_editable | date: 'dd/MM/yyyy'}}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <form [formGroup]="reactiveForm" (ngSubmit)="confirmarInformacionAntesGuardar();">
          <!--TEXTO INFORMATIVO PRINCIPAL-->
          <span class="title-light">Informacion Principal<i class="pl-2 pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div class="form-group row mt-2">
            <!-- primer bloque -->
            <div class="col-md-7">



              <!-- --------itzael id input-------- -->
              <div class="row">
                <div class="col-md-4" style="text-align: center; margin-top: 5px;">
                    <label class="col-sm-3 col-form-label color-font text-center">
                        <span class="input-group-addon">
                            <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
                        </span>
                        IdRuta
                    </label>
                </div>
                <div class="col-sm-8">
                    <input type="text" formControlName="id_ruta_travel" placeholder="ID Ruta/Travel ID*" class="form-control"
                        [style]="{'width':'100%' , 'margin-top': '20px' , 'font-weight':'bold' }">
                    <hr class="my-0">
                    <!-- Leyenda -->
                    <small class="text-muted">*ID asignado por el cliente (MELI obligatorio)</small>
                </div>
            </div>


                    <!-- ------------fin input itzael---------- -->



              <div class="row">
                <div class="col-md-4" style="text-align: center; margin-top: 5px;">
                  <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
                    <span class="input-group-addon"> <i class="pi pi-truck color-icon mr-3" style="font-size: 2rem"></i>
                    </span>
                    Unidad</label>
                </div>
                <div class="col-sm-8">
                  <p-dropdown [options]="lstUnidadesDisponibles" optionLabel="num_economico" formControlName="unidades"
                    autoWidth="false" [showClear]="true" placeholder="Seleccione Unidad*"
                    [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="id_unidad_transporte">
                  </p-dropdown>
                  <hr class="my-0">
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" style="text-align: center; margin-top: 5px;">
                  <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
                    <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
                    </span>
                    Chofer
                  </label>
                </div>
                <div class="col-sm-8">
                  <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo"
                    formControlName="choferes" autoWidth="false" [showClear]="true" placeholder="Seleccione Chofer*"
                    [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="cod_chofer">
                  </p-dropdown>
                  <hr class="my-0">
                </div>
              </div>



              <div class="form-group row mt-3">
              </div>
            </div>

            <!-- segundo bloque -->
            <div class="col-md-5">

              <div class="col-sm-2">
                <div style="position: relative; margin-top: 20px; margin-left: 70px; text-align: right;">
                  <div
                    style="border: 1px solid #ccc; padding: 10px; border-radius: 5px; background-color: #f9f9f9; width: 250px;">
                    <div style="text-align: left;">
                      <h5>Recursos:</h5>
                    </div>
                    <div>
                      <div class="flex  col-header"> &nbsp;&nbsp;&nbsp;&nbsp; Casetas
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p-checkbox formControlName="clv_confirma_casetas" [binary]="true"
                          pTooltip="Confirmacion Casetas"></p-checkbox>
                      </div>
                      <div class="flex  col-header"> &nbsp;&nbsp;&nbsp;&nbsp; Combustible&nbsp;&nbsp;
                        <p-checkbox formControlName="clv_confirma_combustible" [binary]="true"
                          pTooltip="Confirmacion Combustible"></p-checkbox>
                      </div>


                      <div style="text-align: left; margin-top: 7px;">
                        <h5>Apoyos:</h5>
                      </div>
                      <div class="flex  col-header">
                        <div class="flex justify-content-center col-header">
                          &nbsp;&nbsp;&nbsp;&nbsp;Apoyo.Aux&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <p-checkbox formControlName="clv_retardo" [binary]="true"
                            pTooltip="Pago de Apoyo de Auxiliar"></p-checkbox>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <!-- <div class="form-group row mt-2">
            <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
              <span class="input-group-addon"> <i class="pi pi-truck color-icon mr-3" style="font-size: 2rem"></i>
              </span>
              Unidad</label>
            <div class="col-sm-6">
              <p-dropdown [options]="lstUnidadesDisponibles" optionLabel="num_economico" formControlName="unidades"
                autoWidth="false" [showClear]="true" placeholder="Seleccione Unidad*" [style]="{'width':'100%'}"
                optionValue="id_unidad_transporte">
              </p-dropdown>
              <hr class="my-0">
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
              <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
              </span>
              Chofer</label>
            <div class="col-sm-6">
              <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo" formControlName="choferes"
                autoWidth="false" [showClear]="true" placeholder="Seleccione Chofer*" [style]="{'width':'100%'}"
                optionValue="cod_chofer">
              </p-dropdown>
              <hr class="my-0">
            </div>
            <div class="col-sm-2">
              <div class="flex justify-content-center col-header">Apoyo.Aux&nbsp;
                <p-checkbox formControlName="clv_retardo" [binary]="true"
                  pTooltip="Pago de Apoyo de Auxiliar"></p-checkbox>
              </div>
            </div>
          </div> -->
          <hr class="style-two">
          <!--TEXTO INFORMATIVO ADICONALES-->
          <span class="title-light">Informacion de Colaboradores Adicionales<i class="pl-2 pi pi-cog"
              style="size: 2rem;"></i>
          </span>
          <!--Adicional 1-->
          <div class="form-group row mt-2">
            <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
              <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
              </span>
              Adicional 1</label>
            <div class="col-sm-3">
              <p-dropdown [options]="lstpuestos" optionLabel="nombre" formControlName="id_puesto1"
                placeholder="Seleccione Puesto*" autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                [showClear]="true" tooltip="Puesto" tooltipPosition="right"></p-dropdown>
            </div>
            <div class="col-sm-4">
              <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo"
                formControlName="cod_adicional1" optionValue="cod_chofer" autoWidth="false" [showClear]="true"
                placeholder="Seleccione Colaborador*" [style]="{'width':'100%'}">
              </p-dropdown>
              <hr class="my-0">
            </div>
            <div class="col-sm-2">
              <div class="flex justify-content-center">
                <!--<p-checkbox formControlName="clv_retardo1" [binary]="true"
                                    pTooltip="Asistencia Pagada con Retardo"></p-checkbox>-->
              </div>
            </div>
          </div>
          <!--Adicional 2-->
          <div class="form-group row mt-3">
            <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
              <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
              </span>
              Adicional 2</label>
            <div class="col-sm-3">
              <p-dropdown [options]="lstpuestos" optionLabel="nombre" formControlName="id_puesto2"
                placeholder="Seleccione Puesto*" autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                [showClear]="true" tooltip="Puesto" tooltipPosition="right"></p-dropdown>
            </div>
            <div class="col-sm-4">
              <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo"
                formControlName="cod_adicional2" optionValue="cod_chofer" autoWidth="false" [showClear]="true"
                placeholder="Seleccione Colaborador*" [style]="{'width':'100%'}">
              </p-dropdown>
              <hr class="my-0">
            </div>
            <div class="col-sm-2">
              <div class="flex justify-content-center">
                <!--<p-checkbox formControlName="clv_retardo2" [binary]="true"
                                    pTooltip="Asistencia Pagada con Retardo"></p-checkbox>-->
              </div>
            </div>
          </div>
          <div class="mt-5 d-flex justify-content-center">
            <button type="submit" class="btn btn-lg btn-primary" [disabled]="!reactiveForm.valid"
              (dblclick)="dbGuardarInformacionConfirmacion()">Aceptar</button>
          </div>
          <div class="mt-5 d-flex justify-content-center">
            <p *ngIf="reactiveForm.controls['unidades'].disabled">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              Cargando Informacion de Unidades
            </p>
            <p *ngIf="reactiveForm.controls['choferes'].disabled">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              Cargando Informacion de Choferes
            </p>
          </div>
          <div class="mt-5 d-flex justify-content-center">
            <p>
              *Unidades deshabilitadas en Listado cuentan con Ruta sin Finalizar Combustible.
              Verificar con el area de Mesa de Recursos.
            </p>
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarAgregarUnidadyChofer();"></button>
      </ng-template>

    </p-dialog>


    <!--CONSULTA DE INFORMACION DE UNIDADES ASIGNADAS Y CHOFERES-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspEditarConsulta"
      [style]="{width: '85vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
      <ng-template pTemplate="header">
        Consulta de Información
        <div *ngIf="dspEditarConsulta" style="font-size: 18px;">
          <!--<p style="color: orange;display: inline;">{{nombre_ruta_Editable }}</p>&nbsp;-->
          <p style="color:black; display: inline; ">Fecha: {{fecha_dia_editable | date: 'dd/MM/yyyy'}}</p>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <p-table #dt2 [value]="lstOperacionDiariaUnidades" [tableStyle]="{'min-width': '50rem'}"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="550px"
            responsiveLayout="scroll"
            [globalFilterFields]="['num_economico','desc_placaUnidad','nombre_chofer','num_folio']" [paginator]="true"
            [rows]="10" [rowsPerPageOptions]="[10,25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="false"
            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loadingEditar"
            sortField="num_economico">
            <ng-template pTemplate="caption">
              <div class="flex flex-row-reverse flex-wrap">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" size="40" (input)="applyFilterGlobal2($event, 'contains')"
                    placeholder="Buscar Numero Economico,Placa,Folio" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="nombre_ruta" style="min-width: 250px; width: 250px;">
                  Ruta<p-sortIcon field="nombre_ruta"></p-sortIcon>
                </th>
                <th pSortableColumn="num_economico">#Economico <p-sortIcon field="num_economico"></p-sortIcon>
                </th>
                <th pSortableColumn="desc_placaUnidad">Placa</th>
                <th pSortableColumn="desc_generales">Datos Generales</th>
                <th pSortableColumn="nombre_conf_unidad">Unidad</th>
                <th>Puesto</th>
                <th pSortableColumn="nombre_chofer" style="min-width: 150px; width: 150px;">Chofer
                  <p-sortIcon field="nombre_chofer"></p-sortIcon>
                </th>
                <th>Apoyo.Aux</th>
                <th style="min-width: 150px; width: 150px;">Estatus</th>
                <th></th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ruta>
              <tr [ngClass]="{'texto_sm':true}">
                <td class="text-justify" style="font-weight: bold; color: #3B82F6;">{{ruta.nombre_ruta}}
                  <p style="display: inline-block; color: green;">
                    {{ ruta.desc_folio}}</p>
                  <p style="display: inline-block; color: red;" *ngIf="ruta.clv_especial==1">
                    (ESPECIAL) </p>
                    <p style="display: inline-block; color: rgb(246, 130, 6);" *ngIf="ruta.clv_especial==2">
                      (SIN COBRO) </p>
                      <p style="display: inline-block; color: rgb(246, 130, 6);" *ngIf="ruta.id_ruta_travel != ''">
                        (Id: {{  ruta.id_ruta_travel}})</p>
                     
                </td>
                <td class=" text-center" style="font-weight: bold; color: green;">
                  {{ruta.num_economico}}
                </td>
                <td>{{ruta.desc_placaUnidad}}</td>
                <td>{{ruta.desc_generales}}</td>
                <td>{{ruta.nombre_conf_unidad}}</td>
                <td class="text-justify" style="font-weight: bold; color: #3B82F6; size: 8px;">
                  <ol>
                    <li>{{ruta.desc_puesto_principal}}</li>
                    <li *ngIf="ruta.id_puesto1!=0">{{ruta.desc_puesto_adicional1}}</li>
                    <li *ngIf="ruta.id_puesto2!=0">{{ruta.desc_puesto_adicional2}}</li>
                  </ol>
                </td>
                <td class="text-justify" style="font-weight: bold; color: orange; font-size: 12px;">
                  <ol>
                    <li>{{ruta.nombre_chofer}}</li>
                    <li *ngIf="ruta.id_puesto1!=0">{{ruta.nombre_completo_adicional1}}</li>
                    <li *ngIf="ruta.id_puesto2!=0">{{ruta.nombre_completo_adicional2}}</li>
                  </ol>
                </td>
                <td class="text-justify" style="font-weight: bold; color: green; font-size: 12px;">
                  <ol>
                    <li>
                      <p *ngIf="ruta.clv_retardo==0">NO</p>
                      <p *ngIf="ruta.clv_retardo==1">SI</p>
                    </li>
                    <li *ngIf="ruta.id_puesto1!=0">
                      <p *ngIf="ruta.clv_retardo1==0">NO</p>
                      <p *ngIf="ruta.clv_retardo1==1">SI</p>
                    </li>
                    <li *ngIf="ruta.id_puesto2!=0">
                      <p *ngIf="ruta.clv_retardo2==0">NO</p>
                      <p *ngIf="ruta.clv_retardo2==1">SI</p>
                    </li>
                  </ol>
                </td>
                <td>
                  <div class="row">
                    <p *ngIf="ruta.clv_cancelado==1" class="col-lila col-md-6 mt-2">CANCELADA</p>
                    <div *ngIf="(fecha_filtrada==fecha_dia_final) && ruta.clv_cancelado==0" class="col-md-6">
                      <button type="button" class="btn btn-warning p-button-sm" (click)="confirmarCancelarRuta(ruta)"
                        pTooltip="Marcar ruta Cancelada">Cancelar</button>
                    </div>
                    <div *ngIf="(fecha_filtrada==fecha_dia_final) && ruta.clv_cancelado==1" class="col-md-6">
                      <p-button icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"
                        (click)="confirmarCancelarRuta(ruta)" pTooltip="Habilitar Ruta"></p-button>
                    </div>
                  </div>

                  <!--ADMIN-->
                  <div class="row"
                    *ngIf="(fecha_filtrada<fecha_dia_final || fecha_filtrada>fecha_dia_final) && clv_permiso_admin">
                    <div *ngIf="ruta.clv_cancelado==0">
                      <button type="button" class="btn btn-warning" (click)="confirmarCancelarRuta(ruta)"
                        pTooltip="Admin-Marcar ruta Cancelada">Cancelar</button>
                    </div>
                    <div *ngIf="ruta.clv_cancelado==1">
                      <p-button icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"
                        (click)="confirmarCancelarRuta(ruta)" pTooltip="Admin-Habilitar Ruta"></p-button>
                    </div>
                  </div>
                </td>

                <!--botton editar ruta itzael-->
                <td>
                  <button type="button" class="btn btn-primary p-button-sm" (click)="editarUnidadxDia(ruta)"
                    *ngIf="model.clv_operacion != 0" [disabled]="this.permite_editar"
                    pTooltip="Edicion de ruta(gerente)">EditarRuta</button>
                </td>

                  <!-- eliminar itzael -->
                <td>

                    <button type="button" class="btn btn-danger" (click)="confirmarDesactivar(ruta)"
                    *ngIf="model.clv_operacion != 0" [disabled]="this.permite_eliminar"  pTooltip="OPC-Eliminar servicio" tooltipPosition="left">
                      Eliminar
                    </button>


                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarMostrarConsulta()"></button>
      </ng-template>

    </p-dialog>


    <!--itzael ventana emergente-->
    <p-dialog [(visible)]="DilogEditar" [resizable]="false" [draggable]="false"
      [style]="{width: '85vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow" (onHide)="cancelarAgregarUnidadyChofer()"
      >

      <ng-template pTemplate="header">
        Editar Servicio

        <div style="color:black;">
          {{nombre_ruta_Editable }} | {{fecha_dia_editable | date: 'dd/MM/yyyy'}}
        </div>
      </ng-template>

      <!-- check box recuros itzael -->



      <ng-template pTemplate="content">
        <form [formGroup]="reactiveForm" (ngSubmit)="confirmarInformacionAntesGuardar();">
          <!--TEXTO INFORMATIVO PRINCIPAL-->
          <span class="title-light">Informacion Principal<i class="pl-2 pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div class="form-group row mt-2">
            <!-- primer bloque -->
            <div class="col-md-8">

              <!-- -------------inpunt en pestaña editar ------------------------ -->
              <div class="row">
                <div class="col-md-4" style="text-align: center; margin-top: 5px;">
                  <label  class="col-sm-3 col-form-label color-font text-center">
                    <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
                    </span>
                    IdRuta
                  </label>
                </div>
                <div class="col-sm-8">

                  <input type="text" formControlName="id_ruta_travel" [value]="id_ruta_travel" placeholder="ID Ruta/ Travel ID*" class="form-control"
                    [style]="{'width':'100%' , 'margin-top': '20px' , 'font-weight':'bold' }">
                  <hr class="my-0">
                </div>
              </div>

              <!-- ------------------fin del imput itzael------------------ -->
              <div class="row">
                <div class="col-md-4" style="text-align: center; margin-top: 20px;">
                  <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
                    <span class="input-group-addon"> <i class="pi pi-truck color-icon mr-3" style="font-size: 2rem"></i>
                    </span>
                    Unidad</label>
                </div>
                <div class="col-sm-8">
                  <p-dropdown [options]="lstUnidadesDisponibles" optionLabel="num_economico" formControlName="unidades"
                    autoWidth="false" [showClear]="true" placeholder="Seleccione Unidad*"
                    [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="id_unidad_transporte">
                  </p-dropdown>
                  <hr class="my-0">
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" style="text-align: center; margin-top: 20px;">
                  <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
                    <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
                    </span>
                    Chofer
                  </label>
                </div>
                <div class="col-sm-8">
                  <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo"
                    formControlName="choferes" autoWidth="false" [showClear]="true" placeholder="Seleccione Chofer*"
                    [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="cod_chofer">
                  </p-dropdown>
                  <hr class="my-0">
                </div>
              </div>
              <div class="form-group row mt-3">


              </div>
            </div>

            <!-- segundo bloque -->
            <div class="col-md-4">

              <div class="col-sm-2">
                <div style="position: relative; margin-top: 20px; margin-right: 20px; text-align: right;">
                  <div
                    style="border: 1px solid #ccc; padding: 10px; border-radius: 5px; background-color: #f9f9f9; width: 200px;">
                    <div style="text-align: left;">
                      <h5>Recursos:</h5>
                    </div>
                    <div>
                      <div class="flex  col-header"> &nbsp;&nbsp;&nbsp;&nbsp; Casetas
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p-checkbox formControlName="clv_confirma_casetas" [binary]="true"
                          pTooltip="Confirmacion Casetas"></p-checkbox>
                      </div>
                      <div class="flex  col-header"> &nbsp;&nbsp;&nbsp;&nbsp; Combustible&nbsp;&nbsp;
                        <p-checkbox formControlName="clv_confirma_combustible" [binary]="true"
                          pTooltip="Confirmacion Combustible"></p-checkbox>
                      </div>


                      <div style="text-align: left; margin-top: 7px;">
                        <h5>Apoyos:</h5>
                      </div>
                      <div class="flex  col-header">
                        <div class="flex justify-content-center col-header">
                          &nbsp;&nbsp;&nbsp;&nbsp;Apoyo.Aux&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <p-checkbox formControlName="clv_retardo" [binary]="true"
                            pTooltip="Pago de Apoyo de Auxiliar"></p-checkbox>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <hr class="style-two">
          <!--TEXTO INFORMATIVO ADICONALES-->
          <span class="title-light">Informacion de Colaboradores Adicionales<i class="pl-2 pi pi-cog"
              style="size: 2rem;"></i>
          </span>
          <!--Adicional 1-->
          <div class="form-group row mt-2">
            <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
              <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
              </span>
              Adicional 1</label>
            <div class="col-sm-3">
              <p-dropdown [options]="lstpuestos" optionLabel="nombre" formControlName="id_puesto1"
                placeholder="Seleccione Puesto*" autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                [showClear]="true" tooltip="Puesto" tooltipPosition="right"></p-dropdown>
            </div>
            <div class="col-sm-4">
              <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo"
                formControlName="cod_adicional1" optionValue="cod_chofer" autoWidth="false" [showClear]="true"
                placeholder="Seleccione Colaborador*" [style]="{'width':'100%'}">
              </p-dropdown>
              <hr class="my-0">
            </div>
            <div class="col-sm-2">
              <div class="flex justify-content-center">
                <!--<p-checkbox formControlName="clv_retardo1" [binary]="true"
                                      pTooltip="Asistencia Pagada con Retardo"></p-checkbox>-->
              </div>
            </div>
          </div>
          <!--Adicional 2-->
          <div class="form-group row mt-3">
            <label for="lblligero" class="col-sm-3 col-form-label color-font text-center">
              <span class="input-group-addon"> <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
              </span>
              Adicional 2</label>
            <div class="col-sm-3">
              <p-dropdown [options]="lstpuestos" optionLabel="nombre" formControlName="id_puesto2"
                placeholder="Seleccione Puesto*" autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                [showClear]="true" tooltip="Puesto" tooltipPosition="right"></p-dropdown>
            </div>
            <div class="col-sm-4">
              <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo"
                formControlName="cod_adicional2" optionValue="cod_chofer" autoWidth="false" [showClear]="true"
                placeholder="Seleccione Colaborador*" [style]="{'width':'100%'}">
              </p-dropdown>
              <hr class="my-0">
            </div>
            <div class="col-sm-2">
              <div class="flex justify-content-center">
                <!--<p-checkbox formControlName="clv_retardo2" [binary]="true"
                                      pTooltip="Asistencia Pagada con Retardo"></p-checkbox>-->
              </div>
            </div>
          </div>
          <div class="mt-5 d-flex justify-content-center">
            <button type="submit" class="btn btn-lg btn-primary" [disabled]="!reactiveForm.valid"
              (dblclick)="dbGuardarInformacionConfirmacion()">Aceptar</button>
          </div>
          <div class="mt-5 d-flex justify-content-center">
            <p *ngIf="reactiveForm.controls['unidades'].disabled">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              Cargando Informacion de Unidades
            </p>
            <p *ngIf="reactiveForm.controls['choferes'].disabled">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              Cargando Informacion de Choferes
            </p>
          </div>
          <div class="mt-5 d-flex justify-content-center">
            <p>
              *Unidades deshabilitadas en Listado cuentan con Ruta sin Finalizar Combustible.
              Verificar con el area de Mesa de Recursos.
            </p>
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarAgregarUnidadyChofer();"></button>
      </ng-template>
    </p-dialog>
    <!--itzael fin de ventana emergente principal -->



    <!--Confirmar Asignacion de Unidades-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspConfirmarAsignacion"
      [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow" (onHide)="cancelarMostrarConfirmacion()"
      >
      <ng-template pTemplate="header">
        Confirmación de Guardado
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <div class="card-content card-shadow sinborde">
            <div class="row d-flex justify-content-center">
              <div class="col-10">
                <h3 class="text-center">¿Estas Seguro que deseas guardar la Informacion?</h3>
                <table class="table mt-5">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Numero Economico</th>
                      <th scope="col">Nombre de Chofer</th>
                      <th scope="col">Puesto</th>
                      <th scope="col">Apoyo de Auxiliar</th>
                      <th scope="col">Ruta</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of lstConfirmacionColaboradores">
                      <td class="col-blue">{{data.num_economico}}</td>
                      <td class="texto_sm1 text-center">{{data.nombre_completo}}
                      </td>
                      <td class="col-blue text-center">{{data.desc_puesto}}</td>
                      <td class="col-blue text-center">{{data.clv_retardo}}</td>
                      <td class="col-blue">{{ data.desc_ruta}}
                        <div style="color:red;">
                          <p *ngIf="clv_especial==1">(ESPECIAL)</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton label="Cancelar" icon="pi pi-times" class="p-button-warning"
          (click)="cancelarMostrarConfirmacion()"></button>
        <button (click)="guardarUnidadaRutaxDia()" pButton icon="pi pi-check" label="Aceptar" class="p-button-success"
          (dblclick)="dbGuardarInformacionConfirmacion()" [disabled]="desactivarBotonGuardar"></button>
      </ng-template>
    </p-dialog>
  </div>
</div>
