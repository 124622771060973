<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
  rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="container-fluid" style="border-color: blue; border: 5px ; width: 90%;">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-shadow sinborde">
        <div class="card-header sinborde" style="background: none; ">
          <div class="cardicono rounded">
            <i class="pi pi-table mt-3" style="font-size: 2rem; color: #fff;"></i>
          </div>
          <header style="left: 11%; top: 5%;">Operación Diaria
            <div style="color:blueviolet; display: inline;">No Ordinarios</div>
          </header>
          <div class="row d-flex justify-content-end">
            <div class="col-8">
              <div class="form-group row mt-5">
                <div class="col-5">
                  <label for="lblligero" class="col-sm-4 col-form-label color-font text-right"
                    style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                    <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                        style="font-size: 2rem; color: #3B82F6;"></i>
                    </span>
                    Fecha</label>
                  <div class="col-sm-8">
                    <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha"
                      tooltipPosition="top" [(ngModel)]="fecha_dia">
                    <hr class="my-0">
                  </div>
                </div>
                <div class="col-2 d-flex align-items-end">
                  <button pButton pRipple type="button" (click)="cargarInformacionPorFecha(fecha_dia,'1900-01-01');"
                    label="Filtrar" icon="pi pi-filter" [style]="{'width':'100%'}" iconPos="left" class="info"></button>
                </div>
              </div>
            </div>
            <div class="col-2 d-flex align-items-center">
              <!--NORMAL-->
              <button *ngIf="clv_operacion==1 && mostrarbtnLimpiarFiltro && habilitar_un_dia" pButton pRipple
                type="button" (click)="mostrarModalTipoServicio();" label="Registrar"
                class="p-button-raised p-button-success" icon="pi pi-plus-circle" iconPos="left"></button>
              <!--ADMINISTRADOR-->
              <button *ngIf="(clv_operacion==3 ||clv_operacion == 6)&& (mostrarbtnLimpiarFiltro)" pButton pRipple type="button"
                (click)="mostrarModalTipoServicio();" label="Registrar" class="p-button-raised p-button-success"
                icon="pi pi-plus-circle" iconPos="left"></button>
            </div>
            <div class="col-2 d-flex align-items-center">
              <button *ngIf="mostrarbtnLimpiarFiltro" pButton pRipple type="button" (click)="LimpiarFiltro();"
                label="Limpiar Filtro" class="p-button-raised p-button-warning" icon="pi pi-filter-slash" iconPos="left"
                pTooltip="Reiniciar Filtro" tooltipPosition="top"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!--AQUI VA LA TABLA-->
    <p-table #dt1 [value]="lstMovimientos" [scrollable]="true" scrollHeight="flex" scrollDirection="both"
      [paginator]="true" [rows]="150" [rowsPerPageOptions]="[150,200,300,500]" [showCurrentPageReport]="true"
      currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
      [globalFilterFields]="['num_economico','nombre_chofer','nombre_completo_adicional1','nombre_completo_adicional2']"
      [loading]="loading">


      <ng-template pTemplate="caption">
        <div class="flex justify-content-between">
          <button type="button" pButton pRipple class="p-button-success ml-5"
            pTooltip="Exportar TODOS los Movimientos a Excel" [disabled]="ButtonExportExcel"
            tooltipPosition="right" (click)="exportarExcel();">
            <i class="pi pi-file-excel">Exportar</i>
          </button>
          <div class="col-2">
            <button type="button" class="btn btn-warning" style="width: 100%;" (click)="abrirModalReportes()"
              pTooltip="Abrir Ventana" tooltipPosition="left">
              Reportes <i class="pi pi-file-excel"></i>
            </button>
          </div>
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
              placeholder="Buscar Nombre Chofer,Numero Economico" />
          </span>
        </div>

        <!-- filtro -->

        <div class="row d-flex justify-content-end mt-5 mb-5">
          <div class="col-md-3">
            <p-columnFilter type="text" field="desc_tipo_servicio" placeholder="Tipo Servicio"
              class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
          </div>
          <div class="col-md-3">
            <p-columnFilter type="text" field="desc_tipo_asistencia_pagada" placeholder="Descripción"
              class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
          </div>
          <div class="col-md-3">
            <p-columnFilter type="text" field="nombre_completo" placeholder="Colaborador"
              class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
          </div>
          <div class="col-md-3">
            <p-columnFilter type="text" field="desc_ciudad" placeholder="Ciudad" class="form-control sinborde filtro"
              matchMode="contains"></p-columnFilter>
          </div>


        </div>
      </ng-template>

      <!-- itzael agregar campos en pantalla principal de no ordinarios  -->

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 100px;">#</th>

          <th style="width: 200px; min-width: 200px;" pSortableColumn="desc_tipo_servicio">Tipo Servicio<p-sortIcon
              field="desc_tipo_servicio"></p-sortIcon></th>

          <th style="width: 300px;" pSortableColumn="desc_tipo_asistencia_pagada">Descripción<p-sortIcon
              field="desc_tipo_asistencia_pagada"></p-sortIcon></th>

          <!--  -->
          <th style="width: 120px;min-width: 120px;">Tipo Unidad</th>
          <!--  -->
          <th style="width: 150px;min-width: 150px;">Horario</th>

          <th style="width: 100px;" pSortableColumn="desc_tipo_servicio_prestado">Servicio Prestado<p-sortIcon
              field="desc_tipo_servicio_prestado"></p-sortIcon></th>
          <!--  -->
          <th style="width: 120px;min-width: 120px;">Tipo Puesto</th>
          <!--  -->
          <th style="width: 120px;min-width: 120px;">Colaborador</th>
          <th>Economico</th>

          <th style="width: 120px;min-width: 120px;">Cliente</th>

          <th style="width: 120px;min-width: 120px;">Ciudad</th>
          <th style="width: 120px;min-width: 120px;"> Info </th>
          <th *ngIf="clv_operacion == 6" style="width: 120px;min-width: 120px;">Accion</th>
        </tr>
      </ng-template>



      <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
        <tr>
          <td style="width: 100px;">{{ (rowIndex+1) }}</td>
          <td style="width: 300px; font-weight: bold;">{{data.desc_tipo_servicio}}
            <p *ngIf="data.id_tipo_servicio==5 || data.id_tipo_servicio==6" class="text_azul_bajo col-md-6 d-inline"
              pTooltip="{{data.desc_ruta}}">(Ruta*)</p>
          </td>
          <td class="text-center" style="width: 300px;">{{data.desc_tipo_asistencia_pagada}}
          </td>
          <td style="font-weight: bold; font-size: 12px; color: #3B82F6;">
            {{data.desc_confUnidad}}
          </td>
          <td style="width: 150px;min-width: 150px;">{{data.desc_horario}}</td>
          <td style="width: 100px; color: green; font-weight: bold;">{{data.desc_tipo_servicio_prestado}}</td>
          <td style="font-weight: bold; font-size: 12px; color: #3B82F6;">
            {{data.desc_puesto}}
          </td>
          <td style="width: 120px;min-width: 120px; color: blueviolet; font-weight: bold; font-size: 12px;">
            {{data.nombre_completo}}
          </td>
          <td style="font-weight: bold; color: brown;">
            {{data.num_economico}}
            <p *ngIf="data.id_tipo_servicio==2" class="col-orange col-md-6 d-inline" pTooltip="Unidad que se Traslado">
              (*)</p>
          </td>
          <td>
            {{data.desc_cliente}}
          </td>
          <td style="font-weight: bold; font-size: 12px; color: #3B82F6;">
            {{data.desc_ciudad}}
          </td>
          <td>
            <span *ngIf="data.id_tipo_servicio == 2 || data.id_tipo_servicio ==3" class="input-group-addon ml-2 my-0"
            (click)="mostrarInfoExtra(data)"
            pTooltip="Info">
            <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
            </i>
          </span></td>
          <td *ngIf="clv_operacion==6">
            <button class="btn btn-danger btn-sm w-100" [disabled]="data.clv_btn_eliminar == 0" 
              pTooltip="{{tpServicio(data.clv_btn_eliminar,data.id_tipo_servicio)}}" 
              (click)="confirmarEliminar(data.idx,data.id_tipo_servicio)">
              <span class="text-white">Eliminar </span>
              <i class="pi pi-trash text-white"></i>
            </button>
          </td>
          <!-- <td *ngIf="clv_operacion==6 && data.clv_btn_eliminar == 0">
          </td> -->
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!--Agregar Tipo de Servicio-->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspTipoServicio"
    [style]="{width: '80vw','height':'750px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalTipoServicio()">
    <ng-template pTemplate="header">
      <div class="d-flex justify-content-start">
        <div class="col-md-12">Ingresos No Ordinarios {{fecha_dia | date: 'dd/MM/yyyy'}}</div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt1">
        <form [formGroup]="gestionForm" (ngSubmit)="gestionPantallas()">
          <div class="row">
            <div class="col-md-12">
              <span class="title-light mt-3">Tipo de Servicio - Imputable a Ruta<i class="pi pi-cog"
                  style="size: 2rem;"></i>
              </span>
            </div>
          </div>
          <!--Servicio Principal-->
          <div class="row">
            <div class="row form-group col-md-5">
              <label for="lblligero" class="col-sm-2 col-form-label color-font text-right">
                <span class="input-group-addon"> <i class="pi pi-bars color-icon mr-3"
                    style="font-size: 2rem; color: #3B82F6;"></i>
                </span>
              </label>
              <div class="col-sm-10">
                <p-dropdown [options]="lstTipoServicio" optionLabel="nombre" placeholder="Seleccione Tipo de Servicio*"
                  autoWidth="false" [style]="{'width':'100%'}" [showClear]="true" optionValue="id"
                  formControlName="id_tipo_servicio"></p-dropdown>
                <hr class="my-0">
              </div>
            </div>
            <div class="form-group col-md-5"  *ngIf="validCapacitacion()">
              <form [formGroup]="CAForm"> 
                
                  <div class="row g-0">
                    <label for="lblligero" class="col-sm-2 col-form-label color-font text-right">
                      <span class="input-group-addon"> <i class="pi pi-bars color-icon mr-3"
                          style="font-size: 2rem; color: #3B82F6;"></i>
                      </span>
                    </label>
                    <div class="col-sm-10">
                      <p-dropdown [options]="lstTipoCapacitacion" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Seleccione Tipo Capacitación" optionLabel="nombre" [showClear]="true"
                        formControlName="id_tipo" optionValue="id">
                      </p-dropdown>
                    </div>
                  </div>
              </form>
            </div>
            <div class="form-group col-md-2">
              <div class="row g-0">
                <div class="col-sm-12 w-100">
                  <p-button label="Aceptar" icon="pi pi-check-square" [disabled]="validAceptar()"
                    type="submit"></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="mt-2">
        <div class="row" *ngIf="clv_AP && this.gestionForm.dirty" style="height:525px;">
          <form [formGroup]="APForm" (ngSubmit)="guardarInformacionAsistenciaPagada()">
            <div class="row">
              <div class="col-md-6">
                <span class="title-light mt-3">Asistencia Pagada 8hrs<i class="pi pi-cog" style="size: 2rem;"></i>
                </span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstTipoAsistencia" optionValue="id" optionLabel="nombre"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Tipo AP*"
                          formControlName="id_tipo_asistencia_pagada" [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstHorariosAsistenciaPagada" optionValue="id" optionLabel="nombre"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Horario*"
                          formControlName="id_horario" [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- columna izquierda -->
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstTipoServiciosAsistenciaPagada" optionValue="id" optionLabel="nombre"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Servicio*"
                          formControlName="id_servicio_prestado" [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstChoferesDisponibles" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="true"
                          [filter]="true" filterBy="nombre_completo" formControlName="cod_chofer"
                          optionValue="cod_chofer">
                          <ng-template let-item pTemplate="selectedItem">
                            <span style="vertical-align:middle; word-wrap: break-word;"
                              class="texto_responsable">{{item.nombre_completo}}</span>
                          </ng-template>
                          <ng-template let-target pTemplate="item">
                            <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                              pTooltip="{{target.desc_ciudad}} - {{target.desc_puesto}}" tooltipPosition="top">
                              <span
                                style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCiudades" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Seleccione Ciudad*" formControlName="id_ciudad_hm"
                          [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCliente" optionValue="id_cliente" optionLabel="nombre"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Cliente*"
                          formControlName="id_cliente" [showClear]="true" >
                        </p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- cambios itzael se agregan dos combos nuevos  -->
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lscargarConfunidadService" optionValue="clave_configuracionUnidad"
                          optionLabel="nombre" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Configuracion Unidad*" formControlName="clave_configuracionUnidad"
                          [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lsPuestos" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Seleccione Puesto*" formControlName="id_puesto"
                          [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- finde los combos itzael -->

            <!--GUARDAR-->
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100" [disabled]="!APForm.valid || btnHabilitarSpinner">
                  <span class="text-white">Guardar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-2 text-center">
                <i class="pi pi-spin pi-spinner" *ngIf="btnHabilitarSpinner"></i>
              </div>
            </div>
          </form>
        </div>
        <div class="row" *ngIf="clv_TU && this.gestionForm.dirty" style="height:525px;">
          <form [formGroup]="TUForm" (ngSubmit)="guardarInformacionTrasladoUnidad()">
            <div class="row mt-1">
              <div class="col-md-6">
                <span class="title-light mt-3">Traslado de Unidad<i class="pi pi-cog" style="size: 2rem;"></i>
                </span>
              </div>
            </div>
                <!---->
            <div class="row">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-2 mx-0">
                    <span class="input-group-addon"> 
                      <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                    </span>
                  </div>
                  <div class="col-sm-10">
                    <!--<p-dropdown [options]="lstChoferesDisponibles" optionValue="cod_chofer"
                                                optionLabel="nombre_completo" autoWidth="false"
                                                [style]="{'width':'100%'}" placeholder="Seleccione Colaborador*"
                                                formControlName="cod_chofer"
                                                [showClear]="true"></p-dropdown>-->
                    <p-dropdown [options]="lstChoferesDisponibles" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="true"
                      [filter]="true" filterBy="nombre_completo" formControlName="cod_chofer"
                      optionValue="cod_chofer">
                      <ng-template let-item pTemplate="selectedItem">
                        <span style="vertical-align:middle; word-wrap: break-word;"
                          class="texto_responsable">{{item.nombre_completo}}</span>
                      </ng-template>
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{target.desc_ciudad}}" tooltipPosition="top">
                          <span
                            style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-2 mx-0">
                    <span class="input-group-addon"> 
                      <i class="pi pi-dollar color-icon" style="font-size:1.5rem;"></i>
                    </span>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" placeholder="Viaticos" pTooltip="Viaticos"
                                        formControlName="imp_viaticos" min="0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCiudades" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Ciudad Colaborador*" pTooltip="Ciudad Origen"
                          tooltipPosition="right" formControlName="id_ciudad_hm_colab"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!---->

            <div class="row mt-1">
              <div class="col-md-6">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-truck color-icon" style="font-size:1.5rem;"> </i>
                        </span>
                      </div>
                      <div class="col-sm-10">

                        <p-dropdown [options]="lsNumEconomico" optionValue="" optionLabel="num_economico"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione NumEconomico*"
                          [filter]="true" filterBy="num_economico" formControlName="num_economico"
                          formControlName="num_economico"> </p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!---->

            <div class="row mt-1">
              <div class="col-md-6">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCiudades" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Seleccione Ciudad Destino*" pTooltip="Destino del Gasto Operativo"
                          tooltipPosition="right" formControlName="id_ciudad_hm"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCliente" optionValue="id_cliente" optionLabel="nombre"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Cliente*"
                          formControlName="id_cliente"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--GUARDAR-->
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100" [disabled]="!TUForm.valid || btnHabilitarSpinner">
                  <span class="text-white">Guardar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-2 text-center">
                <i class="pi pi-spin pi-spinner" *ngIf="btnHabilitarSpinner"></i>
              </div>
            </div>
          </form>
        </div>
        <div class="row" *ngIf="clv_CA && this.gestionForm.dirty" style="height:525px;">
          <form [formGroup]="CAForm" (ngSubmit)="guardarInformacionCapacitacion()">
            <div class="row mt-1">
              <div class="col-md-6">
                <span class="title-light mt-3">{{this.text_subHeader}} <i class="pi pi-cog" style="size: 2rem;"></i>
                </span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstChoferesDisponibles" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="true"
                          [filter]="true" filterBy="nombre_completo" formControlName="cod_chofer"
                          optionValue="cod_chofer">

                          <ng-template let-item pTemplate="selectedItem">
                            <span style="vertical-align:middle; word-wrap: break-word;"
                              class="texto_responsable">{{item.nombre_completo}}</span>
                          </ng-template>
                          <ng-template let-target pTemplate="item">
                            <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                              pTooltip="{{target.desc_ciudad}} - {{target.desc_puesto}}" tooltipPosition="top">
                              <span
                                style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lsPuestos" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Seleccione Puesto*"
                          formControlName="id_puesto"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCiudades" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Seleccione Ciudad*"
                          formControlName="id_ciudad_hm"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             <!-- ------------------------------itzael puesto nooordianrios---------------------------------- -->
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lscargarConfunidadService" optionValue="clave_configuracionUnidad"
                          optionLabel="nombre" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Configuracion Unidad*" formControlName="clave_configuracionUnidad"
                          [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- -------------------- -->
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCliente" optionValue="id_cliente" optionLabel="nombre"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Cliente*"
                          formControlName="id_cliente"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="habilitaRutas">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <button pButton type="button" 
                            class="p-button-success p-button-raised p-button-text w-100"
                            (click)="mostrarRutasCapacitacion()"
                            pTooltip="Buscar Ruta"
                            icon="pi pi-search" label="Buscar Ruta"
                            [disabled]="validarGestionRutas()">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="!habilitaRutas">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-3">Sueldo Tabulado: 
                        </span>
                        <div class="form-group mt-3">
                          <div class="row g-0">
                            <input pInputText type="number" size="60"
                            formControlName="imp_sueldo"
                            placeholder="00.0" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1" *ngIf="habilitaRutas">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-3">Ruta Seleccionada <i class="pi pi-cog" style="size: 2rem;"></i>
                        </span>
                        <div class="form-group mt-3">
                          <div class="row g-0">
                              <div class="col-sm-12 color-operacion d-flex align-items-center">
                                  <b><p>RUTA: <span class="title-light mt-3"> {{descRuta()}}
                                  </span></p></b>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-3">Sueldo Tabulado: 
                        </span>
                        <div class="form-group mt-3">
                          <div class="row g-0">
                            <input pInputText type="number" size="60"
                            formControlName="imp_sueldo"
                            placeholder="00.0" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-3">Viaticos: 
                        </span>
                        <div class="form-group mt-3">
                          <div class="row g-0">
                            <input pInputText type="number" size="60"
                            formControlName="imp_viaticos"
                            placeholder="00.0" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-3">Hospedaje: 
                        </span>
                        <div class="form-group mt-3">
                          <div class="row g-0">
                            <input pInputText type="number" size="60"
                            formControlName="imp_hospedaje"
                            placeholder="$00.0" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6" *ngIf="habilitaRutas">              
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <button pButton type="button" 
                            class="p-button-success p-button-raised p-button-text w-100"
                            (click)="mostrarRutasCapacitacion()"
                            pTooltip="Buscar Ruta"
                            icon="pi pi-search" label="Buscar Ruta"
                            [disabled]="!editarRuta">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

            <!--GUARDAR-->
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100" [disabled]="!CAForm.valid || btnHabilitarSpinner">
                  <span class="text-white">Guardar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-2 text-center">
                <i class="pi pi-spin pi-spinner" *ngIf="btnHabilitarSpinner"></i>
              </div>
            </div>
          </form>
        </div>
        <div class="row" *ngIf="clv_CE && this.gestionForm.dirty" style="height:525px;">
          <form [formGroup]="CEForm" (ngSubmit)="guardarInformacionCertificacion()">
            <div class="row mt-1">
              <div class="col-md-6">
                <span class="title-light mt-3">Certificación<i class="pi pi-cog" style="size: 2rem;"></i>
                </span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <!--<p-dropdown [options]="lstChoferesDisponibles" optionValue="cod_chofer"
                                                    optionLabel="nombre_completo" autoWidth="false"
                                                    [style]="{'width':'100%'}" placeholder="Seleccione Colaborador*"
                                                    formControlName="cod_chofer"
                                                    [showClear]="true"></p-dropdown>-->
                        <p-dropdown [options]="lstChoferesDisponibles" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="true"
                          [filter]="true" filterBy="nombre_completo" formControlName="cod_chofer"
                          optionValue="cod_chofer">
                          <ng-template let-item pTemplate="selectedItem">
                            <span style="vertical-align:middle; word-wrap: break-word;"
                              class="texto_responsable">{{item.nombre_completo}}</span>
                          </ng-template>
                          <ng-template let-target pTemplate="item">
                            <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                              pTooltip="{{target.desc_ciudad}} - {{target.desc_puesto}}" tooltipPosition="top">
                              <span
                                style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCiudades" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Seleccione Ciudad*"
                          formControlName="id_ciudad_hm"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lstCliente" optionValue="id_cliente" optionLabel="nombre"
                          autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione Cliente*"
                          formControlName="id_cliente"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- -------------------------itzael certificaciones ------------------- -->
            <!-- cambios itzael se agregan dos combos nuevos  -->
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lsPuestos" optionValue="id" optionLabel="nombre" autoWidth="false"
                          [style]="{'width':'100%'}" placeholder="Seleccione Puesto*" formControlName="id_puesto"
                          [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6">
                <!---->
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-2 mx-0">
                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size:1.5rem;"></i>
                        </span>
                      </div>
                      <div class="col-sm-10">
                        <p-dropdown [options]="lscargarConfunidadService" optionValue="clave_configuracionUnidad"
                          optionLabel="nombre" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Configuracion Unidad*" formControlName="clave_configuracionUnidad"
                          [showClear]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <!--GUARDAR-->
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100" [disabled]="!CEForm.valid || btnHabilitarSpinner">
                  <span class="text-white">Guardar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-2 text-center">
                <i class="pi pi-spin pi-spinner" *ngIf="btnHabilitarSpinner"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarModalTipoServicio();"></button>
    </ng-template>
  </p-dialog>
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dgRutas"
                [style]="{width: '55vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
                (onHide)="cancelarDialogoRutas()">
    <ng-template pTemplate="header">
        Catalogo de Rutas a Tabular
    </ng-template>
    <ng-template pTemplate="content">
        <div class="mt-1">
            <p-table #dt2 dataKey="code" [value]="lstRutasDispCapacitacion" [tableStyle]="{ 'min-width': '30rem' }"
                styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="280px"
                [globalFilterFields]="['id_ruta','desc_ruta','desc_ciudad','fecha']">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" size="60"
                                (input)="applyFilterGlobal2($event, 'contains')"
                                placeholder="Buscar Clave Ruta,Nombre Ruta,Ciudad,Fecha Servicio" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Ruta</th>
                        <th>Ciudad</th>
                        <th>Dias Ruta</th>
                        <th>Viaticos</th>
                        <th>Dias Hospedaje</th>
                        <th>Hospedaje</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td class="texto-black">{{ data.desc_ruta }}</td>
                        <td class="text-center">{{ data.desc_ciudad }}</td>
                        <td class="text-center texto-naranja">{{ data.num_dias_mas }}</td>
                        <td class="text-center texto-naranja">{{ data.imp_viaticos }}</td>
                        <td class="text-center col-orange">{{ data.num_dias_hospedaje }}</td>
                        <td class="text-center col-orange">{{ data.imp_hospedaje }}</td>
                        <td><p-button label="seleccionar" icon="pi pi-check" styleClass="p-button-sm"
                                (click)="onRowSelectRuta(data)"></p-button></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarDialogoRutas();"></button>
    </ng-template>
  </p-dialog>
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dgInfoExtra"
                [style]="{width: '55vw','height':'400px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
                (onHide)="cancelarDialogoInforExtra()">
    <ng-template pTemplate="header">
        Informacion {{text_headerInfoExtra}} - {{fecha_dia | date: 'dd/MM/yyyy'}}
    </ng-template>
    <ng-template pTemplate="content">
        <div class="mt-1">
          <div class="row" *ngIf="selectedInfoExtra.id_tipo_servicio == 2 || selectedInfoExtra.id_tipo_servicio == 3" style="height:200px;">
            <div class="col-12 mt-1 g-0">
                <span class="texto-lila">{{this.text_subHeaderInfoExtra}}
                </span>
            </div>
            <div class="row mt-1">
              <div class="col-md-6" *ngIf="selectedInfoExtra.id_tipo_servicio == 3 && selectedInfoExtra.desc_tipo_asistencia_pagada != ''">
                <div class="row">
                  <div class="form-group col-md-10">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-2">Ruta Seleccionada <i class="pi pi-cog" style="size: 2rem;"></i>
                        </span>
                        <div class="form-group mt-2">
                          <div class="row g-0">
                              <div class="col-sm-12 color-operacion d-flex align-items-center">
                                  <b><p>RUTA: <span class="title-light mt-3"> {{selectedInfoExtra.desc_tipo_asistencia_pagada}}
                                  </span></p></b>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="form-group col-md-12">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-2">Sueldo Tabulado: 
                        </span>
                        <div class="form-group mt-2">
                          <div class="row g-0">
                            <input pInputText type="text" size="60"
                            [value]="selectedInfoExtra.imp_sueldo|currency"
                            placeholder="$00.0" [disabled]="true"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="form-group col-md-12">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-2">Viaticos: 
                        </span>
                        <div class="form-group mt-2">
                          <div class="row g-0">
                            <input pInputText type="text" size="60"
                            [value]="selectedInfoExtra.imp_viaticos|currency"
                            placeholder="$00.0" [disabled]="true"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2" *ngIf="selectedInfoExtra.id_tipo_servicio == 3">
                <div class="row">
                  <div class="form-group col-md-12">
                    <div class="row g-0">
                      <div class="col-sm-12">
                        <span class="title-light mt-2">Hospedaje: 
                        </span>
                        <div class="form-group mt-2">
                          <div class="row g-0">
                            <input pInputText type="text" size="60"
                            [value]="selectedInfoExtra.imp_hospedaje|currency"
                            placeholder="$00.0" [disabled]="true"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarDialogoInforExtra();"></button>
    </ng-template>
  </p-dialog>
</div>
