import { CostounidadService } from './../services/costounidad.service';
import { Component } from '@angular/core';
import { IUnidadTransporteCompleto } from '../models/unidadtransportecompleto';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { IEstatus } from '../models/estatus';
import { IUnidadTransporte } from '../models/unidadTransporte';
import { IMes } from '../models/mes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { CiudadService } from '../services/ciudad.service';
import { ConfunidadService } from '../services/confunidad.service';
import { EstatusunidadService } from '../services/estatusunidad.service';
import { UnidadService } from '../services/unidad.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IHistoricoCostoUnidad } from '../models/historicocosto';
import { IDataCostoUnidad } from '../models/data_costounidad';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IEncabezadoCosto } from '../models/encabezados_costos';
import { IFolioMensual } from '../models/FolioMensual';
import { IrptFolioRutaMensual } from '../models/rptFolioMensual';
import { ICostoVentaMensual } from '../models/costoventamensual';
import { IrptUnidadesCostoVentaMensual } from '../models/rptUnidadesCostoVentaMensual';
import { IDiferenciaCosto } from '../models/diferencia_operacion';
import { IConcentradoProyecto } from '../models/concentrado_proyecto';
import { IRptConcentradoProyecto } from '../models/rptConcentradoProyecto';
import { IrptDiferenciaOperacion } from '../models/rptDiferenciaOperacion';
import { ITipoRenta } from '../models/tipoRenta';
import { ITipoDiasTrabajados } from '../models/tipoDiasTrabajados';
import { AdminProveedoresService } from '../services/admproveedores.service';
import { IProveedor, IProveedorSimp, IProveedorSimp2 } from '../models/proveedor';
@Component({
  selector: 'app-costounidad',
  templateUrl: './costounidad.component.html',
  styleUrls: ['./costounidad.component.scss']
})
export class CostounidadComponent {
  @ViewChild('dt1') dt!: Table;
  lstUnidadesTransporte: IUnidadTransporte[] = [];
  lstUnidades: IUnidadTransporteCompleto[] = [];
  lstTipoRenta: ITipoRenta[] = [];
  lstDiasTrabajados: ITipoDiasTrabajados[] = [];
  lstProveedor: IProveedorSimp[] = [];
  color_gris_deshabilitadas: string = '#D9D9D9';
  color_naranja_baja: string = '#FFDAB7';
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVAS'
  },
  {
    id: 4,
    nombre: 'ACTIVAS CON REPORTE'
  },
  {
    id: 3,
    nombre: 'BAJA DE OPERACIÓN'
  },
  {
    id: 0,
    nombre: 'INACTIVAS'
  }];
  loading: boolean = false;
  loading_aprovechamiento_renta: boolean = false;
  loading_difencia_renta: boolean = false;
  loading_concentrado_renta: boolean = false;
  loading_Mensual_renta: boolean =false;
  dsbHerramientas: boolean = false;
  dbsReportes: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];
  selectedMes: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anio: any;
  REGEX_NUMBER: any = '^[0-9]{6}$';
  herramientasForm: FormGroup = new FormGroup({
    idx: new FormControl(0, [Validators.required]),
    num_economico: new FormControl({ value: '', disabled: true }, [Validators.required]),
    id_unidad_transporte: new FormControl(0, [Validators.required]),
    imp_depreciacion: new FormControl(null, [Validators.required, Validators.pattern(this.REGEX_NUMBER)]),
    imp_interes: new FormControl(null, [Validators.pattern(this.REGEX_NUMBER)]),
    imp_seguro: new FormControl(null, [Validators.required, Validators.pattern(this.REGEX_NUMBER)]),
    imp_arrendamiento: new FormControl(null, [Validators.required, Validators.pattern(this.REGEX_NUMBER)]),
    imp_renta: new FormControl(null, [Validators.pattern(this.REGEX_NUMBER)]),
    imp_sub_contratacion: new FormControl(null, [Validators.required, Validators.pattern(this.REGEX_NUMBER)]),
    imp_gps_1: new FormControl(null,[Validators.required, Validators.pattern(this.REGEX_NUMBER)]),
    imp_gps_2: new FormControl(null,[Validators.required, Validators.pattern(this.REGEX_NUMBER)]),
    id_tipo_renta: new FormControl({ value: 1, disabled: true }, [Validators.required]),
    id_dias_trabajados: new FormControl({ value: 0, disabled: true }, [Validators.required]),
    clave_proveedor: new FormControl(0,[Validators.required]),
    num_mes: new FormControl(0, [Validators.required]),
    num_anio: new FormControl(0, [Validators.required])
  });
  habilitar_edicion_costo: boolean = true;
  user: ICredencialUsuario = {};
  lstHistoricoPorUnidad: IHistoricoCostoUnidad[] = [];
  dsbAgregarHerramientas: boolean = false;
  num_economico: string = '';
  datos_generales: string = '';
  nombre_tipounidad: string = '';
  proveedor: string = '';
  clave_tipoUnidad: number = 0;
  fecha_dia: any;
  fecha_fin: any;
  loadinglst: boolean = false;
  //
  clonedProducts: { [s: string]: IHistoricoCostoUnidad } = {};
  //editar
  dsbAgregarHerramientasEditar: boolean = false;
  clv_operacion: number = 0;
  lstEncabezados: IEncabezadoCosto[] = [];
  lstdata: IEncabezadoCosto[] = [];
  //objetos dinamicos
  obj_rptcostomensual: any;
  obj_encabezadosmensual: any;
  lst_data_sincastear: any = [];
  lst_data_exportar: any[] = [];
  loading_reporte: boolean = false;
  //reporte
  lstCostoVentaReporte: ICostoVentaMensual[] = [];
  lstrptCostoVentaReporte: IrptUnidadesCostoVentaMensual[] = [];
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporte1: any;
  loading_reporte1: boolean = false;
  //Reporte Concentrado por Proyecto
  lstConcentradoProyecto: IConcentradoProyecto[] = [];
  lstrptConcentradoProyecto: IRptConcentradoProyecto[] = [];
  fecha_diaconcentrado: any;
  fecha_finconcentrado: any;
  fecha_diaAprovRenta: any;
  fecha_finAprovRenta: any;
  fecha_diaConcentradoRenta: any;
  fecha_finConcentradoRenta: any;
  fecha_diaConcentradoGPS: any;
  fecha_finConcentradoGPS: any;
  loading_reporte_concentrado: boolean = false;

  //Reporte Diferencia Costo de Operacion
  lstDiferenciaCostoOperacion: IDiferenciaCosto[] = [];
  lstrptDiferenciaCostoOperacion: IrptDiferenciaOperacion[] = [];
  selectedMesReporteDiferencia: IMes = {
    num_mes: -1,
    nombre: ""
  }
  selectedMesReporteDiferenciaRentaSub : IMes = {
    num_mes: -1,
    nombre: ""
  }
  selectedMesReporteMensualRentaSub : IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporteDiferenciaRentaSub: any;
  anioReporteDiferencia: any;
  anioReporteMes: any;
  loading_reporte_diferencia: boolean = false;

  loading_Mensual_GPS: boolean = false
  loading_difencia_GPS: boolean = false;
  loading_concentrado_GPS: boolean = false;
  selectedMesReporteMensualGPS: IMes = {
    num_mes: -1,
    nombre: ""
  }
  selectedMesReporteDiferenciaGPS: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporteGPS: any
  anioReporteDiferenciaGPS: any

  constructor(private confirmationService: ConfirmationService, private messageService: MessageService,
    private _unidadService: UnidadService,
    private auth: AuthService,
    private ciudadService: CiudadService,
    private costounidadService: CostounidadService,
    private admProv: AdminProveedoresService) {
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.inicializarFechas();
    this.cargarListadoTodasLasUnidades();
    this.getListaDeTipoRenta();
    this.getListaTipoDiasTrabajados();
    this.getListaProveedores();
    //this.generarEstructuraReporte('2023-11-01','2023-11-07');
  }

  getListaDeTipoRenta() {
    this.costounidadService.getListadoTipoRenta().subscribe((resp) => {
      this.lstTipoRenta = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaTipoDiasTrabajados() {
    this.costounidadService.getListadoTipoDiasTrabajados().subscribe((resp) => {
      this.lstDiasTrabajados = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaProveedores() {
    this.admProv.getLstProveedoresSimply(0).subscribe((resp) => {
      this.lstProveedor = resp;
      this.lstProveedor.push({clave_Proveedor:0,nombre:'Sin Proveedor',fk_clave_Estado:''});
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  changedpIdRenta(event: any)
  {
    if (event.value == 1 ) {
      this.herramientasForm.controls['id_dias_trabajados'].setValue(1);
      this.herramientasForm.controls['id_dias_trabajados'].disable();
    } else{
      this.herramientasForm.controls['id_dias_trabajados'].enable();
      this.herramientasForm.controls['id_dias_trabajados'].setValue(2)
    }    
  }

  changedpIdRentaManual(data: number)
  {
    if (data == 1 ) {
      this.herramientasForm.controls['id_dias_trabajados'].setValue(1);
    } else{   
      this.herramientasForm.controls['id_dias_trabajados'].setValue(2)
    }    
  }

  generarEstructuraReporte(fecha: string, fecha_fin: string) {
    this.loading_reporte = true;
    if ((fecha == null || typeof fecha === 'undefined' || fecha == '') || (fecha_fin == null || typeof fecha_fin === 'undefined' || fecha_fin == '')) {
      this.loading_reporte = false;
      this.mostrarDialogoInformativo('Debe proporcionar Fecha Inicio y Fin para generar el reporte.');
    } else {
      this.costounidadService.getListadoEncabezados(fecha, fecha_fin, 0).subscribe((resp) => {
        this.lstEncabezados = resp;
        this.obj_encabezadosmensual = this.crearObjeto(this.lstEncabezados);
      },
        (error) => {
          this.loading_reporte = false;
          this.messageService.add({ severity: 'error', summary: 'Error en <Encabezados-Generar Reporte Costo de Venta>', detail: 'Contacte al Administrador del Sitio' });
        });

      //llenar información del data principal
      this.costounidadService.getInformacionReporte(fecha, fecha_fin).subscribe((resp) => {
        this.lst_data_sincastear = resp;
        //Validar si tienen información
        this.asignarArregloParaExportar(this.lst_data_sincastear);
        setTimeout(() => {
          this.loading_reporte = false;
        },
          800);
      },
        (error) => {
          this.loading_reporte = false;
          this.messageService.add({ severity: 'error', summary: 'Error en <Generar Reporte Costo de Venta>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  asignarArregloParaExportar(data: any[]) {
    /*data = [
      {
        numeroeconomico: "001-002",
        mie01: "110.15",
        jue02: "210.15",
        vie03: "310.15",
        sab04: "410.15",
        dom05: "510.15",
        lun06: "610.15",
        mar07: "710.15",
      },
      {
        numeroeconomico: "001-003",
        mie01: "110.15",
        jue02: "210.15",
        vie03: "310.15",
        sab04: "410.15",
        dom05: "510.15",
        lun06: "610.15",
        mar07: "710.15",
      },
    ];*/
    let data_cast: any = [];
    let header: any = {};
    for (const dat of data) {
      //
      /*Aqui se debe inicializar el header*/
      //
      header = {};
      for (const [key, value] of Object.entries(dat)) {
        if (key === "numeroeconomico") {
          header.NumeroEconomico = value;
        } else {
          header[key.charAt(0).toUpperCase() + key.slice(1)] = value;
          //header[key.charAt(0).toUpperCase() + key.slice(1)] = value;
        }
      }
      data_cast.push(header);
    }
    if (data_cast.length > 0) {
      this.generarReporteExcelCasteado(data_cast, 'AprovechamientodeUnidadesparaCostodeVenta');
    } else {
      this.mostrarDialogoInformativo('No existe información para generar el Reporte.');
    }
  }

  generarReporteExcelCasteado(data: any, nombre_reporte: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      let texto: string = this.fecha_fin == '1900-01-01' || this.fecha_fin == null ? '' : '_Al_' + this.fecha_fin;
      this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + this.fecha_dia + texto);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName /*+ "_export_" + dateFormat */ + EXCEL_EXTENSION);

  }

  crearObjeto(lst: IEncabezadoCosto[]): any {
    //recorrer un objeto y obtener su valor
    let nuevoObj: any = {};

    for (const dato of lst) {
      for (const [key, value] of Object.entries(dato)) {
        nuevoObj[value] = "";
      }
    }

    return nuevoObj;
  }

  generarReporte1() {
    this.loading_reporte1 = true;
    this.lstCostoVentaReporte = [];
    this.lstrptCostoVentaReporte = [];
    if ((this.selectedMesReporte1 == null || this.selectedMesReporte1.num_mes == -1) || (this.anioReporte1 === 'undefined' || this.anioReporte1 == null)) {
      this.mostrarMensajeDeAlerta();
      this.loading_reporte1 = false;
    } else {
      let mes = this.selectedMesReporte1.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporte1 instanceof Date) {
        // will execute
        anio_cast = this.anioReporte1.getFullYear();
      } else {
        anio_cast = this.anioReporte1;
      }
      this.cargarInformacionDelMes(1, mes, anio_cast);
    }
  }

  cargarInformacionDelMes(num_opcion: number, num_mes: number, num_anio: number) {
    // aqui se manda el servicio a llamar se llena rpt_ListadoUnidades
    this.costounidadService.getListadoCostoMensual(num_opcion, num_mes, num_anio).subscribe((resp) => {
      this.lstCostoVentaReporte = resp;
      this.asignarArregloParaExportarReporte1(this.lstCostoVentaReporte);
      if (this.lstrptCostoVentaReporte.length == 0 || typeof this.lstrptCostoVentaReporte === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.lstrptCostoVentaReporte);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "ReporteCostoMensualDeUnidadCreditoYPropias" + '_' + this.selectedMesReporte1.nombre + '_' + num_anio);
        });
      }
      this.loading_reporte1 = false;
    },
      (error) => {
        this.loading_reporte1 = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <ReporteCostoMensualDeUnidadCreditoYPropias>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  asignarArregloParaExportarReporte1(data: ICostoVentaMensual[]) {
    data.map((valor) => {
      this.lstrptCostoVentaReporte.push({
        NumeroEconomico: valor.num_economico,
        Placa: valor.desc_placaUnidad,
        VIN: valor.vin,
        Descripcion: valor.datos_generales,
        Transporte: valor.nombre_tipotransporte,
        TipoDeUnidad: valor.nombre_tipounidad,
        ConfiguracionUnidad: '',
        CiudadOperacion: valor.nombre_ciudaddestino,
        Estatus_Subestatus: (valor.desc_estatus! + ' ' + valor.desc_sub_estatus!),
        Depreciacion: valor.imp_depreciacion,
        Interes: valor.imp_interes,
        Seguro: valor.imp_seguro,
        Arrendamiento: valor.imp_arrendamiento,
        // TipoRenta: valor.desc_tipo_renta,
        // Renta: valor.imp_renta,
        // SubContratacion: valor.imp_sub_contratacion,
        NombredelMes: valor.nom_mes,
        Anio: valor.num_anio
      });
    });
  }

  asignarArregloParaExportarConcentradoProyecto(data: IConcentradoProyecto[]) {
    data.map((valor) => {
      this.lstrptConcentradoProyecto.push({
        FolioProyecto: valor.num_folio_proyecto,
        ImporteDepreciacion: valor.imp_depreciacion,
        ImporteInteres: valor.imp_interes,
        ImporteSeguro: valor.imp_seguro,
        ImporteArrendamiento: valor.imp_arrendamiento,
       
      });
    });
  }

  asignarArregloParaExportarDiferencia(data: IDiferenciaCosto[]) {
    data.map((valor) => {
      this.lstrptDiferenciaCostoOperacion.push({
        CiudadOperacion: valor.nom_ciudad_operacion,
        ImporteDepreciacion: valor.imp_depreciacion,
        ImporteInteres: valor.imp_interes,
        ImporteSeguro: valor.imp_seguro,
        ImporteArrendamiento: valor.imp_arrendamiento,
        ImporteRenta: valor.imp_renta,
        ImporteSubcontratacion: valor.imp_sub_contratacion
      });
    });
  }


  mostrarMensajeDeAlerta() {
    this.loading = false;
    this.loading_reporte1 = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Mes y/o Año',
      detail: 'Mes y Año deben seleccionarse para consultar informacion.'
    });
  }

  cancelarMensajeConfirmacion() {
    this.messageService.clear('confirm');
  }

  confirmarMensaje() {
    this.messageService.clear('confirm');
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_fin = dateFormat;

    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMes.nombre = mes.nombre;
        this.selectedMes.num_mes = mes.num_mes;
        //
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
        //
        this.selectedMesReporteDiferencia.nombre = mes.nombre;
        this.selectedMesReporteDiferencia.num_mes = mes.num_mes;
      }
    });
    this.anio = getYear;
    this.anioReporte1 = getYear;
    this.anioReporteDiferencia= getYear;
  }

  cargarListadoTodasLasUnidades() {
    this.lstUnidades = [];
    this.loading = true;
    this.costounidadService.getListado(this.user.cod!).subscribe((resp) => {
      this.lstUnidades = resp;
      if (this.lstUnidades.length > 0) {
        this.clv_operacion = this.lstUnidades[0].clv_operacion!;
      }
      setTimeout(() => {
        this.loading = false;
      },
        800);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En -Listar Unidades-. No existe informacion del Catalogo para mostrar');
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Unidades>', detail: 'Contacte al Administrador del Sitio' });
        this.loading = false;
      });
  }



  gestionarCostoUnidad(data: IUnidadTransporteCompleto) {
    this.loadinglst = true;
    this.dsbHerramientas = true;
    this.num_economico = data.num_economico;
    this.datos_generales = data.desc_marca + ' ' + data.desc_modelo + ' ' + data.desc_color + '(' + data.num_anio + ')';
    this.nombre_tipounidad = data.nombre_tipounidad;
    this.lstHistoricoPorUnidad = data.lstHistorico!;
    this.clave_tipoUnidad = data.clave_tipoUnidad;
    setTimeout(() => {
      this.loadinglst = false;
    },
      800);
  }

  cancelarModalCosto() {
    this.dsbHerramientas = false;
    this.num_economico = '';
    this.datos_generales = '';
    this.nombre_tipounidad = '';
    this.clave_tipoUnidad = 0;
    this.lstHistoricoPorUnidad = [];
  }



  guardarInformacionCosto() {

  }

  mostrarModalAgregarCosto(data: IUnidadTransporteCompleto) {
    this.habilitar_edicion_costo = true;
    this.clave_tipoUnidad = data.clave_tipoUnidad;
    this.num_economico = data.num_economico;
    this.datos_generales = data.desc_marca + ' ' + data.desc_modelo + ' ' + data.desc_color + '(' + data.num_anio + ')';
    this.nombre_tipounidad = data.nombre_tipounidad;
    this.proveedor = data.desc_proveedor;
    this.herramientasForm.controls['id_unidad_transporte'].setValue(data.id_unidad_transporte);
    this.herramientasForm.controls['num_economico'].setValue(data.num_economico);
    this.inicializarFechas();
    this.herramientasForm.controls['id_tipo_renta'].setValue(data.id_tipo_renta);
    this.herramientasForm.controls['id_dias_trabajados'].setValue(data.id_dias_trabajados);
    this.herramientasForm.controls['clave_proveedor'].setValue(data.clave_proveedor);
    this.herramientasForm.controls['num_mes'].setValue(this.selectedMes.num_mes);
    this.herramientasForm.controls['num_anio'].setValue(this.anio);
    this.changedpIdRentaManual(data.id_tipo_renta);

    this.validarValidacionesPorTipoUnidad(data.clave_tipoUnidad);
    this.dsbAgregarHerramientas = true;
  }

  validarValidacionesPorTipoUnidad(clave_tipoUnidad: number) {
    this.herramientasForm.get('imp_gps_1')!.clearValidators();
    this.herramientasForm.get('imp_gps_2')!.clearValidators();
    this.herramientasForm.get('imp_gps_1')!.setValidators(Validators.required);
    this.herramientasForm.get('imp_gps_2')!.setValidators(Validators.required);
    switch (clave_tipoUnidad) {
      case 1: { //PROPIA
        this.deshabilitarInput();
        this.iniciarConValorCero();
        this.herramientasForm.get('imp_depreciacion')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_interes')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_seguro')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_arrendamiento')!.setValidators(Validators.required);
        //No son Obligatorias
        this.herramientasForm.get('imp_renta')!.clearValidators();
        this.herramientasForm.get('imp_sub_contratacion')!.clearValidators();
        this.herramientasForm.get('id_tipo_renta')!.clearValidators();
        this.herramientasForm.get('id_dias_trabajados')!.clearValidators();
        break;
      }
      case 2: { //ARRENDADA - RENTA
        this.deshabilitarInput();
        this.iniciarConValorCero();
        this.herramientasForm.get('imp_renta')!.setValidators(Validators.required);
        this.herramientasForm.get('id_tipo_renta')!.setValidators(Validators.required);
        this.herramientasForm.get('id_dias_trabajados')!.setValidators(Validators.required);
        //No son Obligatorias
        this.herramientasForm.get('imp_depreciacion')!.clearValidators();
        this.herramientasForm.get('imp_interes')!.clearValidators();
        this.herramientasForm.get('imp_seguro')!.clearValidators();
        this.herramientasForm.get('imp_arrendamiento')!.clearValidators();
        this.herramientasForm.get('imp_sub_contratacion')!.clearValidators();
        break;
      }
      case 3: { //CREDITO
        this.deshabilitarInput();
        this.iniciarConValorCero();
        this.herramientasForm.get('imp_depreciacion')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_interes')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_seguro')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_arrendamiento')!.setValidators(Validators.required);
        //No son Obligatorias
        this.herramientasForm.get('imp_renta')!.clearValidators();
        this.herramientasForm.get('imp_sub_contratacion')!.clearValidators();
        this.herramientasForm.get('id_tipo_renta')!.clearValidators();
        this.herramientasForm.get('id_dias_trabajados')!.clearValidators();
        break;
      }
      case 4: { //SUB-CONTRATADAS
        this.deshabilitarInput();
        this.iniciarConValorCero();
        this.herramientasForm.get('imp_sub_contratacion')!.setValidators(Validators.required);
        //No son Obligatorias
        this.herramientasForm.get('imp_depreciacion')!.clearValidators();
        this.herramientasForm.get('imp_interes')!.clearValidators();
        this.herramientasForm.get('imp_seguro')!.clearValidators();
        this.herramientasForm.get('imp_arrendamiento')!.clearValidators();
        this.herramientasForm.get('imp_renta')!.clearValidators();
        this.herramientasForm.get('id_tipo_renta')!.clearValidators();
        this.herramientasForm.get('id_dias_trabajados')!.clearValidators();
        break;
      }
      default: {
        //todas permitan ingresar información y son obligatorias
        this.deshabilitarInput();
        this.iniciarConValorCero();
        this.herramientasForm.get('imp_depreciacion')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_interes')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_seguro')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_arrendamiento')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_renta')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_sub_contratacion')!.setValidators(Validators.required);
        this.herramientasForm.get('id_tipo_renta')!.setValidators(Validators.required);
        this.herramientasForm.get('id_dias_trabajados')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_gps_1')!.setValidators(Validators.required);
        this.herramientasForm.get('imp_gps_2')!.setValidators(Validators.required);
        break;
      }
    }
  }

  iniciarConValorCero() {
    this.herramientasForm.get('idx')!.setValue(0);
    this.herramientasForm.get('imp_depreciacion')!.setValue(0);
    this.herramientasForm.get('imp_interes')!.setValue(0);
    this.herramientasForm.get('imp_seguro')!.setValue(0);
    this.herramientasForm.get('imp_arrendamiento')!.setValue(0);
    this.herramientasForm.get('imp_renta')!.setValue(0);
    this.herramientasForm.get('imp_sub_contratacion')!.setValue(0);
    this.herramientasForm.get('imp_gps_1')!.setValue(0);
    this.herramientasForm.get('imp_gps_2')!.setValue(0);
    // this.herramientasForm.get('id_tipo_renta')!.setValue(0);
    // this.herramientasForm.get('id_dias_trabajados')!.setValue(0);
  }

  habilitarInput() {
    this.herramientasForm.get('num_mes')!.enable();
    this.herramientasForm.get('num_anio')!.enable();
    this.herramientasForm.get('imp_depreciacion')!.enable();
    this.herramientasForm.get('imp_interes')!.enable();
    this.herramientasForm.get('imp_seguro')!.enable();
    this.herramientasForm.get('imp_arrendamiento')!.enable();
    this.herramientasForm.get('imp_renta')!.enable();
    this.herramientasForm.get('imp_sub_contratacion')!.enable();
    this.herramientasForm.get('imp_gps_1')!.enable();
    this.herramientasForm.get('imp_gps_2')!.enable();
    this.herramientasForm.get('id_tipo_renta')!.enable();
    this.herramientasForm.get('id_dias_trabajados')!.enable();
    this.herramientasForm.get('clave_proveedor')!.enable();
  }

  deshabilitarInput() {
    this.herramientasForm.get('num_mes')!.disable();
    this.herramientasForm.get('num_anio')!.disable();
    this.herramientasForm.get('imp_depreciacion')!.disable();
    this.herramientasForm.get('imp_interes')!.disable();
    this.herramientasForm.get('imp_seguro')!.disable();
    this.herramientasForm.get('imp_arrendamiento')!.disable();
    this.herramientasForm.get('imp_renta')!.disable();
    this.herramientasForm.get('imp_sub_contratacion')!.disable();
    this.herramientasForm.get('imp_gps_1')!.disable();
    this.herramientasForm.get('imp_gps_2')!.disable();
    this.herramientasForm.get('id_tipo_renta')!.disable();
    this.herramientasForm.get('id_dias_trabajados')!.disable();
    this.herramientasForm.get('clave_proveedor')!.disable();
  }

  habilitarEdicionCosto(clave_tipoUnidad: number) {
    this.habilitar_edicion_costo = false;
    switch (clave_tipoUnidad) {
      case 1: { //PROPIA,CREDITO
        this.habilitarInput();
        this.iniciarConValorCero();
        this.herramientasForm.get('imp_renta')!.disable();
        this.herramientasForm.get('imp_sub_contratacion')!.disable();
        this.herramientasForm.get('id_tipo_renta')!.disable();
        this.herramientasForm.get('id_dias_trabajados')!.disable();
        break;
      }
      case 2: { //ARRENDA - RENTA
        this.habilitarInput();
        this.iniciarConValorCero();
        //
        this.herramientasForm.get('imp_depreciacion')!.disable();
        this.herramientasForm.get('imp_interes')!.disable();
        this.herramientasForm.get('imp_seguro')!.disable();
        this.herramientasForm.get('imp_arrendamiento')!.enable();
        this.herramientasForm.get('imp_sub_contratacion')!.enable();
        this.herramientasForm.get('id_tipo_renta')!.enable();
        this.herramientasForm.get('id_dias_trabajados')!.enable();
        break;
      }
      case 3: { //CREDITO
        this.habilitarInput();
        this.iniciarConValorCero();
        this.herramientasForm.get('imp_renta')!.disable();
        this.herramientasForm.get('imp_sub_contratacion')!.disable();
        this.herramientasForm.get('id_tipo_renta')!.disable();
        this.herramientasForm.get('id_dias_trabajados')!.disable();
        break;
      }
      case 4: { //SUB-CONTRATADAS
        this.habilitarInput();
        this.iniciarConValorCero();
        //
        this.herramientasForm.get('imp_depreciacion')!.disable();
        this.herramientasForm.get('imp_interes')!.disable();
        this.herramientasForm.get('imp_seguro')!.disable();
        this.herramientasForm.get('imp_arrendamiento')!.disable();
        this.herramientasForm.get('imp_renta')!.disable();
        this.herramientasForm.get('id_tipo_renta')!.disable();
        this.herramientasForm.get('id_dias_trabajados')!.disable();
        break;
      }
      default: {
        //todas permitan ingresar información y son obligatorias
        this.habilitarInput();
        this.iniciarConValorCero();
        break;
      }
    }
  }

  habilitarEditarCostoMensual(clave_tipoUnidad: number) {
    this.habilitar_edicion_costo = false;
    switch (clave_tipoUnidad) {
      case 1: { //PROPIA
        this.habilitarInput();
        this.herramientasForm.get('imp_renta')!.disable();
        this.herramientasForm.get('imp_sub_contratacion')!.disable();
        this.herramientasForm.get('id_tipo_renta')!.disable();
        this.herramientasForm.get('id_dias_trabajados')!.disable();
        this.herramientasForm.get('clave_proveedor')!.disable();
        this.herramientasForm.get('num_mes')!.disable();
        this.herramientasForm.get('num_anio')!.disable();
        break;
      }
      case 2: { //ARRENDA - RENTA
        this.habilitarInput();
        //
        this.herramientasForm.get('imp_depreciacion')!.disable();
        this.herramientasForm.get('imp_interes')!.disable();
        this.herramientasForm.get('imp_seguro')!.disable();
        this.herramientasForm.get('imp_arrendamiento')!.disable();
        this.herramientasForm.get('imp_sub_contratacion')!.disable();
        this.herramientasForm.get('id_tipo_renta')!.enable();
        this.herramientasForm.get('id_dias_trabajados')!.enable();
        this.herramientasForm.get('clave_proveedor')!.enable();
        this.herramientasForm.get('num_mes')!.disable();
        this.herramientasForm.get('num_anio')!.disable();
        break;
      }
      case 3: { //CREDITO
        this.habilitarInput();
        this.herramientasForm.get('imp_renta')!.disable();
        this.herramientasForm.get('imp_sub_contratacion')!.disable();
        this.herramientasForm.get('id_tipo_renta')!.disable();
        this.herramientasForm.get('id_dias_trabajados')!.disable();
        this.herramientasForm.get('clave_proveedor')!.disable();
        this.herramientasForm.get('num_mes')!.disable();
        this.herramientasForm.get('num_anio')!.disable();
        break;
      }
      case 4: { //SUB-CONTRATADAS
        this.habilitarInput();
        //
        this.herramientasForm.get('imp_depreciacion')!.disable();
        this.herramientasForm.get('imp_interes')!.disable();
        this.herramientasForm.get('imp_seguro')!.disable();
        this.herramientasForm.get('imp_arrendamiento')!.disable();
        this.herramientasForm.get('imp_renta')!.disable();
        this.herramientasForm.get('clave_proveedor')!.disable();
        this.herramientasForm.get('num_mes')!.disable();
        this.herramientasForm.get('num_anio')!.disable();
        break;
      }
      default: {
        //todas permitan ingresar información y son obligatorias
        this.habilitarInput();
        break;
      }
    }

  }

  cancelarModalAgregarCosto() {
    this.habilitar_edicion_costo = true;
    this.clave_tipoUnidad = 0;
    this.dsbAgregarHerramientas = false;
    this.num_economico = '';
    this.datos_generales = '';
    this.nombre_tipounidad = '';
    this.herramientasForm.reset();
  }

  guardarInformacionAgregarCosto() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    let data_enviar: IDataCostoUnidad = {
      idx: 0,
      id_unidad_transporte: this.herramientasForm.get('id_unidad_transporte')?.value,
      num_economico: this.herramientasForm.get('num_economico')?.value,
      imp_depreciacion: this.herramientasForm.get('imp_depreciacion')?.value,
      imp_interes: this.herramientasForm.get('imp_interes')?.value,
      imp_seguro: this.herramientasForm.get('imp_seguro')?.value,
      imp_arrendamiento: this.herramientasForm.get('imp_arrendamiento')?.value,
      imp_renta: this.herramientasForm.get('imp_renta')?.value,
      imp_sub_contratacion: this.herramientasForm.get('imp_sub_contratacion')?.value,
      imp_gps_1: this.herramientasForm.get('imp_gps_1')?.value,
      imp_gps_2: this.herramientasForm.get('imp_gps_2')?.value,
      cod_usuario: this.user.cod!,
      num_mes: this.herramientasForm.get('num_mes')?.value,
      num_anio: this.herramientasForm.get('num_anio')?.value,
      fecha_registro: fecha_registro_momento,
      id_tipo_renta: this.herramientasForm.get('id_tipo_renta')?.value,
      id_dias_trabajados: this.herramientasForm.get('id_dias_trabajados')?.value,
      clave_proveedor:this.herramientasForm.get('clave_proveedor')?.value     
    };
    this.costounidadService.guardarCostoUnidad(data_enviar).subscribe((resp) => {
      let rst_estatus: IResultadoGeneral = {
        clv_estatus: 0,
        messageDetails: ''
      };
      rst_estatus = resp;
      if (rst_estatus.clv_estatus == 208) {
        this.mostrarDialogoInformativo(rst_estatus.messageDetails);
      } else if (rst_estatus.clv_estatus == -1) {
        this.mostrarDialogoInformativo(rst_estatus.messageDetails);
      } else {
        setTimeout(() => {
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
        },
          300);
        this.cancelarModalAgregarCosto();
        this.cargarListadoTodasLasUnidades();
      }
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Costo de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  guardarInformacionEditarCosto() {
    this.loadinglst = true;
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    let data_enviar: IDataCostoUnidad = {
      idx: 0,
      id_unidad_transporte: this.herramientasForm.get('id_unidad_transporte')?.value,
      num_economico: this.herramientasForm.get('num_economico')?.value,
      imp_depreciacion: this.herramientasForm.get('imp_depreciacion')?.value,
      imp_interes: this.herramientasForm.get('imp_interes')?.value,
      imp_seguro: this.herramientasForm.get('imp_seguro')?.value,
      imp_arrendamiento: this.herramientasForm.get('imp_arrendamiento')?.value,
      imp_renta: this.herramientasForm.get('imp_renta')?.value,
      imp_sub_contratacion: this.herramientasForm.get('imp_sub_contratacion')?.value,
      imp_gps_1: this.herramientasForm.get('imp_gps_1')?.value,
      imp_gps_2: this.herramientasForm.get('imp_gps_2')?.value,
      cod_usuario: this.user.cod!,
      num_mes: this.herramientasForm.get('num_mes')?.value,
      num_anio: this.herramientasForm.get('num_anio')?.value,
      fecha_registro: fecha_registro_momento,
      id_tipo_renta: this.herramientasForm.get('id_tipo_renta')?.value,
      id_dias_trabajados: this.herramientasForm.get('id_dias_trabajados')?.value,
      clave_proveedor:this.herramientasForm.get('clave_proveedor')?.value
    };
    this.costounidadService.actualizarCostoPorMes(data_enviar).subscribe((resp) => {
      this.cancelarModalEditarCosto();
      setTimeout(() => {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
      },
        300);
      //Volver a cargar listado con sus objetos de historico, no solo el que se esta editando.
      this.cargarListadoTodasLasUnidades();
      this.lstHistoricoPorUnidad = resp;
      setTimeout(() => {
        this.loadinglst = false;
      },
        500);
    },
      (error) => {
        this.loadinglst = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Costo de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  abrirModalReportes() {
    this.inicializarFechas();
    this.dbsReportes = true;
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    this.selectedMes = {
      num_mes: 0,
      nombre: ''
    }
    this.anio = null;

    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
  }

  exportarExcel() {

  }

  mostrarModalEditarCosto(data: IHistoricoCostoUnidad) {
    this.validarValidacionesPorTipoUnidad(this.clave_tipoUnidad);
    this.habilitar_edicion_costo = true;
    this.herramientasForm.controls['idx'].setValue(data.id_unidad_transporte);
    this.herramientasForm.controls['id_unidad_transporte'].setValue(data.id_unidad_transporte);
    this.herramientasForm.controls['num_economico'].setValue(data.num_economico);
    this.herramientasForm.controls['imp_depreciacion'].setValue(data.imp_depreciacion);
    this.herramientasForm.controls['imp_interes'].setValue(data.imp_interes);
    this.herramientasForm.controls['imp_seguro'].setValue(data.imp_seguro);
    this.herramientasForm.controls['imp_arrendamiento'].setValue(data.imp_arrendamiento);
    this.herramientasForm.controls['imp_renta'].setValue(data.imp_renta);
    this.herramientasForm.controls['imp_sub_contratacion'].setValue(data.imp_sub_contratacion);
    this.herramientasForm.controls['imp_gps_1'].setValue(data.imp_gps_1);
    this.herramientasForm.controls['imp_gps_2'].setValue(data.imp_gps_2);
    this.herramientasForm.controls['id_tipo_renta'].setValue(data.id_tipo_renta);
    this.herramientasForm.controls['id_dias_trabajados'].setValue(data.id_dias_trabajados);
    this.herramientasForm.controls['clave_proveedor'].setValue(data.clave_proveedor);  
    this.herramientasForm.controls['num_mes'].setValue(data.num_mes);
    this.herramientasForm.controls['num_anio'].setValue(data.num_anio);
    this.changedpIdRentaManual(data.id_tipo_renta);
    this.dsbAgregarHerramientasEditar = true;
  }

  cancelarModalEditarCosto() {
    this.dsbAgregarHerramientasEditar = false;
    this.habilitar_edicion_costo = true;
    this.herramientasForm.reset();
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  onRowEditInit(data: IHistoricoCostoUnidad) {
    this.clonedProducts = {};
    this.clonedProducts[data.idx] = { ...data };
  }

  generarReporteConcentradoProyecto(fecha: string, fecha_fin: string) {
    this.loading_reporte_concentrado = true;
    this.lstConcentradoProyecto=[];
    this.lstrptConcentradoProyecto=[];
    if ((fecha == null || typeof fecha === 'undefined' || fecha == '') || (fecha_fin == null || typeof fecha_fin === 'undefined' || fecha_fin == '')) {
      this.loading_reporte_concentrado = false;
      this.mostrarDialogoInformativo('Debe proporcionar Fecha Inicio y Fin para generar el reporte.');
    } else {

      //llenar información del data principal
      this.costounidadService.getReporteConcentradoInicioYFin(fecha, fecha_fin).subscribe((resp) => {
        this.lstConcentradoProyecto = resp;
        //Validar si tienen información
        this.asignarArregloParaExportarConcentradoProyecto(this.lstConcentradoProyecto);
        if (this.lstrptConcentradoProyecto.length == 0 || typeof this.lstrptConcentradoProyecto === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el periodo indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.lstrptConcentradoProyecto);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='CostoDeUnidadesConcentrado';
            let texto: string = fecha_fin == '1900-01-01' || fecha_fin == null ? '' : '_Al_' + fecha_fin;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + fecha + texto);
          });
        }
        setTimeout(() => {
          this.loading_reporte_concentrado = false;
        },
          800);
      },
        (error) => {
          this.loading_reporte_concentrado = false;
          this.messageService.add({ severity: 'error', summary: 'Error en <Generar Reporte Concentrado por Proyecto>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  generarReporteDiferenciaCosto() {
    this.loading_reporte_diferencia = true;
    this.lstDiferenciaCostoOperacion = [];
    this.lstrptDiferenciaCostoOperacion = [];
    if ((this.selectedMesReporteDiferencia == null || this.selectedMesReporteDiferencia.num_mes == -1) || (this.anioReporteDiferencia === 'undefined' || this.anioReporteDiferencia == null)) {
      this.mostrarMensajeDeAlerta();
      this.loading_reporte_diferencia = false;
    } else {
      let mes = this.selectedMesReporteDiferencia.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporteDiferencia instanceof Date) {
        // will execute
        anio_cast = this.anioReporteDiferencia.getFullYear();
      } else {
        anio_cast = this.anioReporteDiferencia;
      }
      this.cargarInformacionDelMesDiferencia(1, mes, anio_cast);
    }
  }

  cargarInformacionDelMesDiferencia(num_opcion: number, num_mes: number, num_anio: number) {
    this.costounidadService.getReporteCostoOperacionDiferenciaMensual(num_mes, num_anio).subscribe((resp) => {
      this.lstDiferenciaCostoOperacion = resp;
      this.asignarArregloParaExportarDiferencia(this.lstDiferenciaCostoOperacion);
      if (this.lstrptDiferenciaCostoOperacion.length == 0 || typeof this.lstrptDiferenciaCostoOperacion === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.lstrptDiferenciaCostoOperacion);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "ReporteCostoMensualDiferenciasCiudad" + '_' + this.selectedMesReporteDiferencia.nombre + '_' + num_anio);
        });
      }
      this.loading_reporte_diferencia = false;
    },
      (error) => {
        this.loading_reporte_diferencia = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <ReporteCostoMensualDiferenciasCiudad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  generarReporteAprovechamientoRenta(fecha: string, fecha_fin: string) {
    this.loading_aprovechamiento_renta = true;
    // this.lstConcentradoProyecto=[];
    // this.lstrptConcentradoProyecto=[];
    if ((fecha == null || typeof fecha === 'undefined' || fecha == '') || (fecha_fin == null || typeof fecha_fin === 'undefined' || fecha_fin == '')) {
      this.loading_aprovechamiento_renta = false;
      this.mostrarDialogoInformativo('Debe proporcionar Fecha Inicio y Fin para generar el reporte.');
    } else {

      //llenar información del data principal
      this.costounidadService.getReporteAprovechamientoRentaSubInicioYFin(fecha, fecha_fin).subscribe((resp) => {
        //this.lstConcentradoProyecto = resp;
        //Validar si tienen información
        //this.asignarArregloParaExportarConcentradoProyecto(this.lstConcentradoProyecto);
        if (resp.length == 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el periodo indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='AprovechamientoDeUnidadesRentaySub';
            let texto: string = fecha_fin == '1900-01-01' || fecha_fin == null ? '' : '_Al_' + fecha_fin;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + fecha + texto);
          });
        }
        setTimeout(() => {
          this.loading_aprovechamiento_renta = false;
        },
          800);
      },
        (error) => {
          this.loading_aprovechamiento_renta = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error en <Generar Reporte Aprovechamiento de unidades Renta y SubContrataciones>' });
        });
    }
  }

  generarDiferenciaRenta() {
    this.loading_difencia_renta = true;
    if ((this.selectedMesReporteDiferenciaRentaSub == null || this.selectedMesReporteDiferenciaRentaSub.num_mes == -1) 
        || (this.anioReporteDiferenciaRentaSub === 'undefined' || this.anioReporteDiferenciaRentaSub == null)) {
      this.mostrarMensajeDeAlerta();
      this.loading_difencia_renta = false;
    } else {
      let mes = this.selectedMesReporteDiferenciaRentaSub.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporteDiferenciaRentaSub instanceof Date) {
        // will execute
        anio_cast = this.anioReporteDiferenciaRentaSub.getFullYear();
      } else {
        anio_cast = this.anioReporteDiferenciaRentaSub;
      }
      //llenar información del data principal
      this.costounidadService.getReporteDiferenciasRentaSubInicioYFin(mes, anio_cast).subscribe((resp) => {      
        //Validar si tienen información
        if (resp.length == 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el Mes y Año indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='ReporteCostoMensualDiferenciasCiudadRentaySub';
            let texto: string = mes+'_'+anio_cast;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + texto);
          });
        }
        setTimeout(() => {
          this.loading_difencia_renta = false;
        },
          800);
      },
        (error) => {
          this.loading_difencia_renta = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error en <Generar Reporte Diferencia del mes Rentas y Subcontrataciones>' });
        });
    }
  }

  generarReporteConcentradoProyectoRenta(fecha: string, fecha_fin: string) {
    this.loading_concentrado_renta = true;
    // this.lstConcentradoProyecto=[];
    // this.lstrptConcentradoProyecto=[];
    if ((fecha == null || typeof fecha === 'undefined' || fecha == '') || (fecha_fin == null || typeof fecha_fin === 'undefined' || fecha_fin == '')) {
      this.loading_reporte_concentrado = false;
      this.mostrarDialogoInformativo('Debe proporcionar Fecha Inicio y Fin para generar el reporte.');
    } else {

      //llenar información del data principal
      this.costounidadService.getReporteConcentradoRentaSubInicioYFin(fecha, fecha_fin).subscribe((resp) => {
        //this.lstConcentradoProyecto = resp;
        //Validar si tienen información
        if (resp.length == 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el periodo indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='CostoDeUnidadesConcentradoRentaySub';
            let texto: string = fecha_fin == '1900-01-01' || fecha_fin == null ? '' : '_Al_' + fecha_fin;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + fecha + texto);
          });
        }
        setTimeout(() => {
          this.loading_concentrado_renta = false;
        },
          800);
      },
        (error) => {
          this.loading_concentrado_renta = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error en <Generar Reporte Concentrado por Proyecto> Renta y Subcontrataciones>' });
        });
    }
  }

  generarReporteMensualRentadasySub()
  {
    this.loading_Mensual_renta = true;
    // this.lstConcentradoProyecto=[];
    // this.lstrptConcentradoProyecto=[];
    let mes = this.selectedMesReporte1.num_mes;
    let anio_cast: any = 0;
    if (this.anioReporte1 instanceof Date) {
      // will execute
      anio_cast = this.anioReporte1.getFullYear();
    } else {
      anio_cast = this.anioReporte1;
    }
    this.loading_Mensual_renta = true;
    if ((this.selectedMesReporteMensualRentaSub == null || this.selectedMesReporteMensualRentaSub.num_mes == -1) 
        || (this.anioReporteMes === 'undefined' || this.anioReporteMes == null)) {
      this.mostrarMensajeDeAlerta();
      this.loading_Mensual_renta = false;
    } else {
      let mes = this.selectedMesReporteMensualRentaSub.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporteMes instanceof Date) {
        // will execute
        anio_cast = this.anioReporteMes.getFullYear();
      } else {
        anio_cast = this.anioReporteMes;
      }
      //llenar información del data principal
      this.costounidadService.getReporteMensualRentaSub(mes, anio_cast).subscribe((resp) => {      
        //Validar si tienen información
        if (resp.length == 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el Mes y Año indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='ReporteCostoMensualDeUnidadRentasySubcontrataciones';
            let texto: string = mes+'_'+anio_cast;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + texto);
          });
        }
        setTimeout(() => {
          this.loading_Mensual_renta = false;
        },
          800);
      },
        (error) => {
          this.loading_Mensual_renta = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error en <Generar Reporte Mensual Rentas y Subcontrataciones>' });
        });
    }
  }

  generarDiferenciaGPS() {
    this.loading_difencia_GPS = true;
    if ((this.selectedMesReporteDiferenciaGPS == null || this.selectedMesReporteDiferenciaGPS.num_mes == -1) 
        || (this.anioReporteDiferenciaGPS === 'undefined' || this.anioReporteDiferenciaGPS == null)) {
      this.mostrarMensajeDeAlerta();
      this.loading_difencia_GPS = false;
    } else {
      let mes = this.selectedMesReporteDiferenciaGPS.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporteDiferenciaGPS instanceof Date) {
        // will execute
        anio_cast = this.anioReporteDiferenciaGPS.getFullYear();
      } else {
        anio_cast = this.anioReporteDiferenciaGPS;
      }
      //llenar información del data principal
      this.costounidadService.getReporteMensualDiferenciaGPS(mes, anio_cast).subscribe((resp) => {      
        //Validar si tienen información
        if (resp.length == 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el Mes y Año indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='ReporteCostoMensualDiferenciasCiudadGPS';
            let texto: string = mes+'_'+anio_cast;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + texto);
          });
        }
        setTimeout(() => {
          this.loading_difencia_GPS = false;
        },
          800);
      },
        (error) => {
          this.loading_difencia_GPS = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error en <Generar Reporte Diferencia del mes GPS>' });
        });
    }
  }

  generarReporteConcentradoProyectoGPS(fecha: string, fecha_fin: string) {
    this.loading_concentrado_GPS = true;
    // this.lstConcentradoProyecto=[];
    // this.lstrptConcentradoProyecto=[];
    if ((fecha == null || typeof fecha === 'undefined' || fecha == '') || (fecha_fin == null || typeof fecha_fin === 'undefined' || fecha_fin == '')) {
      this.loading_concentrado_GPS = false;
      this.mostrarDialogoInformativo('Debe proporcionar Fecha Inicio y Fin para generar el reporte.');
    } else {

      //llenar información del data principal
      this.costounidadService.getReporteConcentradoGPS(fecha, fecha_fin).subscribe((resp) => {
        //this.lstConcentradoProyecto = resp;
        //Validar si tienen información
        if (resp.length == 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el periodo indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='CostoDeUnidadesConcentradoGPS';
            let texto: string = fecha_fin == '1900-01-01' || fecha_fin == null ? '' : '_Al_' + fecha_fin;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + fecha + texto);
          });
        }
        setTimeout(() => {
          this.loading_concentrado_GPS = false;
        },
          800);
      },
        (error) => {
          this.loading_concentrado_GPS = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error en <Generar Reporte Concentrado por Proyecto> GPS>' });
        });
    }
  }

  generarReporteMensualGPS()
  {
    this.loading_Mensual_GPS = true;
    // this.lstConcentradoProyecto=[];
    // this.lstrptConcentradoProyecto=[];
    let mes = this.selectedMesReporte1.num_mes;
    let anio_cast: any = 0;
    if (this.anioReporte1 instanceof Date) {
      // will execute
      anio_cast = this.anioReporte1.getFullYear();
    } else {
      anio_cast = this.anioReporte1;
    }
    this.loading_Mensual_GPS = true;
    if ((this.selectedMesReporteMensualGPS == null || this.selectedMesReporteMensualGPS.num_mes == -1) 
        || (this.anioReporteGPS === 'undefined' || this.anioReporteGPS == null)) {
      this.mostrarMensajeDeAlerta();
      this.loading_Mensual_GPS = false;
    } else {
      let mes = this.selectedMesReporteMensualGPS.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporteGPS instanceof Date) {
        // will execute
        anio_cast = this.anioReporteGPS.getFullYear();
      } else {
        anio_cast = this.anioReporteGPS;
      }
      //llenar información del data principal
      this.costounidadService.getReporteMensualGPS(mes, anio_cast).subscribe((resp) => {      
        //Validar si tienen información
        if (resp.length == 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion para exportar(Excel) en el Mes y Año indicado');
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            let nombre_reporte:string='ReporteCostoMensualDeUnidadGPS';
            let texto: string = mes+'_'+anio_cast;
            this.saveAsExcelFile(excelBuffer, nombre_reporte + '_' + texto);
          });
        }
        setTimeout(() => {
          this.loading_Mensual_GPS = false;
        },
          800);
      },
        (error) => {
          this.loading_Mensual_GPS = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error en <Generar Reporte Mensual GPS>' });
        });
    }
  }

}
